import React from 'react'
import "@google/model-viewer/dist/model-viewer";
import './View.css'
import { deleteModel } from '../../../actions/Models';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
const ViewModel = ( { modelLoader, deleteModel, model } ) =>
{
	const history = useHistory()
	const { projId } = useParams()
	const deleteHandler = ( id ) =>
	{
		deleteModel( id, result =>
		{
			const { status } = result
			if ( status == 1 )
			{
				history.push( `/projects/${projId}` )
			}
		} )
	}

	return (
		<div className='model'>
			{ model ? <div className='model-view'>
				<div className="header">
					<h2 className='title'>{ model?.Model_Name }</h2>
					<div>
						<button
							className='btn btn-warning'
							onClick={ () => deleteHandler( model?._id ) }
						>Delete</button >
					</div>
				</div>
				<div id="model-body">
					<div className="model">
						<model-viewer
							style={ { width: '500px', height: '500px' } }
							src={ model ? model.Format_Source ? model.Format_Source[ "GLTF" ] : model.Resource_Url : "" }
							ios-src={ model && model.Format_Source[ "USDZ" ] ? model.Format_Source[ "USDZ" ] : "" }
							poster=""
							alt={ model?.Model_Name }
							shadow-intensity="1"
							camera-controls
							// auto-rotate
							ar
						></model-viewer>
					</div>
					<div className="model-details">
						<table border={ 5 }>
							<tbody>
								<tr>
									<td className='label'>Model Name</td>
									<td className="content">{ model?.Model_Name }</td>
								</tr>
								<tr>
									<td className='label'>Model Id</td>
									<td className="content">{ model?._id }</td>
								</tr>
								<tr>
									<td className='label'>User Id</td>
									<td className="content">{ model?.User_Id }</td>
								</tr>
								<tr>
									<td className='label'>Type</td>
									<td className="content">{ model?.Type }</td>
								</tr>
								<tr>
									<td className='label'>GLTF/GLB</td>
									<td className="content">{ model ? model[ "Format_Source" ][ "GLTF" ] : "" }</td>
								</tr>
								<tr>
									<td className='label'>USDZ</td>
									<td className="content">{ model ? model[ "Format_Source" ][ "USDZ" ] : "" }</td>
								</tr>
								<tr>
									<td className='label'>Created At</td>
									<td className="content">{ model?.createdAt?.split( "T" )[ 0 ] }</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div> : <h3>Model Not Found</h3> }
		</div>
	)
}


const mapPropsToState = ( state ) =>
{
	return {
		modelLoader: state?.model?.modelsLoader,
		model: state?.model?.currentModel,
	}
}

export default connect( mapPropsToState, { deleteModel } )( ( withRouter( ViewModel ) ) );


