import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import './View.css'

const ViewProject = ( { project } ) =>
{
	return (
		<div className='project-view'>
			<div className="header">
				<h2 className='title'>{ project?.Project_Name }</h2>
				<div>
					<button className={ "btn btn-danger" + project?.Status?.toLowerCase() == "active" ? "btn-success" : "btn-danger" } >{ project?.Status.toUpperCase() }</button >

					<button className={ "btn " + project?.Draft == true ? "btn-warning" : "btn-secondary" } >{ project?.Draft == true ? "DRAFT" : "PUBLISHED" }</button >
				</div>
			</div>
			<div id="project-body">
				<p className='description'>{ project?.Description }</p>
				<p>Project Url: <a href={ project?.Url }>{ project?.Url }</a></p>
				{ project?.Url && <div>
					<iframe
						height="500px"
						style={ { border: "1px solid black" } }
						width="100%"
						src={ project?.Url }
						title="View Project">

					</iframe>
				</div> }
			</div>

		</div>
	)
}


const mapPropsToState = ( state ) =>
{
	return {
		project: state?.project?.currentProject,
	}
}

export default connect( mapPropsToState, {} )( ( withRouter( ViewProject ) ) );


