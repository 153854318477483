import React, { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { getModelById } from '../../actions/Models';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ViewModel from '../../components/models/ViewModelComponent/View';
import Loader from '../../components/loader/Loader';



const ModelDetail = ( { getModelById, currentModel, modelLoader } ) =>
{
	const [ value, setValue ] = useState( 0 );
	const [ model, setModel ] = useState( null )
	const { modelId } = useParams()
	const history = useHistory()
	const handleChange = ( event, newValue ) =>
	{
		setValue( newValue );
	};

	useEffect( () =>
	{
		console.log( "first" )
		getModelById( modelId )
	}, [] )

	useEffect( () =>
	{
		setModel( currentModel )
	}, [ currentModel ] )


	return (
		<div style={ { height: "100%" } }>
			{ modelLoader === false ? model ? <div>
				<div>
					<Tabs
						defaultActiveKey="model"
						id="fill-tab-example"
						className="project-tabs mb-3"
						fill
					>
						<Tab eventKey="model" title="Model">
							<ViewModel />
						</Tab>
					</Tabs>
				</div>
			</div> : "Model" : <Loader /> }
		</div>
	);
}

const mapPropsToState = ( state ) =>
{
	return {
		modelLoader: state?.model?.modelsLoader,
		currentModel: state?.model?.currentModel,
	}
}

export default connect( mapPropsToState, { getModelById } )( ( withRouter( ModelDetail ) ) );


