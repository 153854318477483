import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams, withRouter } from 'react-router-dom'
import { getUserById, updateUserInfo, banUser, deleteUser } from '../../actions/Users'
import Loader from '../../components/loader/Loader'
import ViewUser from '../../components/users/ViewUserComponent/View'

const EditUser = ( { getUserById, banUser, deleteUser, updateUserInfo, currentUser, userLoader } ) =>
{
	const { userId } = useParams()
	const [ user, setUser ] = useState( currentUser )

	useEffect( () =>
	{
		getUserById( userId )
	}, [] )

	useEffect( () =>
	{
		setUser( currentUser )
	}, [ currentUser ] )


	return (
		<div>
			{
				userLoader === false ?
					user ? <div className='edit-user'>
						{/* <h1 style={ { marginTop: 0, marginBottom: "10px" } }>User Profile</h1> */ }
						<ViewUser user={ user } updateUserInfo={ updateUserInfo } banUser={ banUser } deleteUser={ deleteUser } />
					</div> : "User Not Found"
					:
					<Loader />
			}
		</div>
	)
}


const mapPropsToState = ( state ) =>
{
	return {
		currentUser: state?.users?.currentUser,
		userLoader: state?.users?.userLoader,
	}
}

export default connect( mapPropsToState, { getUserById, updateUserInfo, banUser, deleteUser } )( ( withRouter( EditUser ) ) );
