import GoogleLogo from "../../../assets/images/google-logo.png";
import useAuth from "../../../hooks/useAuth";
import React, { useState } from "react";
import { useHistory, Link, Redirect } from "react-router-dom";
import './LoginPage.css'
import Swal from "sweetalert2";

const Login = () =>
{
	const { admin, handleAdminLogin, error, logOut, googleSignin, facebookSignin } = useAuth();

	const [ email, setEmail ] = useState( "" );
	const [ password, setPassword ] = useState( "" );
	const [ agree, setAgree ] = useState( false );
	const history = useHistory();

	// capture the email
	const handleEmail = ( e ) =>
	{
		setEmail( e.target.value );
		console.log( email );
	};

	// capture the password
	const hanldePassword = ( e ) =>
	{
		setPassword( e.target.value );
		console.log( password );
	};

	const handleLogin = () =>
	{
		if ( email !== 'xarwinar@gmail.com' )
		{
			Swal.fire( { title: "Admin email not found!" } )
		} else
		{
			handleAdminLogin( email, password, history );
		}
	};


	// google login
	const googleLogin = () =>
	{
		googleSignin( history );
	};

	// facebook login
	const facebookLogin = () =>
	{
		facebookSignin( history );
	};
	//

	const signUp = () =>
	{
		history.push( "/register" );
	};
	console.log( admin )
	if ( admin.email )
		return <Redirect to="/dashboard" />
	return (
		<div className="authwrapper">
			<div className="login-form-container">
				<h1>Hello ! Welcome back.</h1>
				<p>Log in with your credentials</p>
				{ error ? (
					<p style={ { color: "red" } }>
						{ " " }
						enter a valid email address or password{ " " }
					</p>
				) : (
					<p></p>
				) }
				<div className="login-form">
					<div className="formgroup">
						<label>Email Address</label>
						<input
							type="text"
							name="email"
							placebolder="Email Address"
							onBlur={ handleEmail }
						/>
					</div>
					<div className="formgroup">
						<label>
							Password
							<Link to="/forgot-password">
								<span>Forgot Password?</span>
							</Link>
						</label>
						<input
							type="password"
							name="password"
							placebolder="Password"
							onBlur={ hanldePassword }
						/>
					</div>

					<br />

					<div className="login-buttons">
						<button className="btn btn-primary" onClick={ handleLogin }>Log in</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Login;
