
import { FaBell } from 'react-icons/fa';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './Topbar.css'


const TopBar = () =>
{
	// const userImage = user.photoURL

	const [ isProfileOpen, setIsProfileOpen ] = useState( false );
	const history = useHistory();
	const location = useLocation();

	const navTo = ( path ) =>
	{
		setIsProfileOpen( false );
		history.push( path );
	};

	// let pageTitle = 'Dashboard'
	// switch ( location.pathname )
	// {
	// 	case '/dashboard':
	// 		pageTitle = 'Dashboard';
	// 		break;
	// 	case '/projects':
	// 		pageTitle = 'My Projects';
	// 		break;
	// 	case '/models':
	// 		pageTitle = 'Models';
	// 		break;
	// 	case '/templates':
	// 		pageTitle = 'Templates';
	// 		break;
	// 	case '/settings':
	// 		pageTitle = 'Settings';
	// 		break;
	// 	case '/users':
	// 		pageTitle = 'All Users';
	// 		break;
	// 	case '/profile':
	// 		pageTitle = 'Edit Profile';
	// 		break;
	// 	case '/plans':
	// 		pageTitle = 'Subscription Plans';
	// 		break;
	// 	case '/notifications':
	// 		pageTitle = 'Notifications';
	// 		break;
	// 	case '/change-password':
	// 		pageTitle = 'Change Password';
	// 		break;
	// 	case '/payment-history':
	// 		pageTitle = 'Payment History';
	// 		break;
	// 	case '/templates':
	// 		pageTitle = 'Templates';
	// 		break;
	// 	case '/template/create':
	// 		pageTitle = 'Create Template';
	// 		break;
	// 	default:
	// 		pageTitle = 'Dashboard';
	// 		break;
	// }

	let pageTitle = location.pathname.split( "/" )[ 1 ].replace( '-', " " ).toUpperCase();

	const createProject = () =>
	{
		history.push( "/createprojects" );
	}


	return (
		<div className='topbarWrapper'>
			<div className='pageTitle'>
				<h3>{ pageTitle }</h3>
			</div>
			<div className='ProfileActionSection'>
				<div className='userInfo' onClick={ () => setIsProfileOpen( !isProfileOpen ) }>
					<img src='https://via.placeholder.com/50' alt='user' />
					<h1 onClick={ () => setIsProfileOpen( !isProfileOpen ) }>
						Xarwin
					</h1>
					{ isProfileOpen && (
						<div className='profileMenu'>
							<h3 onClick={ () => navTo( '/profile' ) }>Edit Profile</h3>
							<h3 onClick={ () => navTo( '/terms-conditions' ) }>
								Terms &amp; Conditions
							</h3>
							<h3 onClick={ () => navTo( '/privacy-policy' ) }>Privacy Policy</h3>
						</div>
					) }
				</div>
				<FaBell onClick={ () => history.push( '/notifications' ) } />
			</div>
		</div>
	);
};

export default TopBar;
