import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Admin from './Admin';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import AuthProvider from './hooks/AuthProvider';
import { Auth0Provider } from '@auth0/auth0-react';
import store from './setup/Store';

const domain = "dev-saifmaamun.us.auth0.com";
const clientId = "H9K5TAKgeW8syfxnt5c0CYMKJUW8GLEx";
const root = ReactDOM.createRoot( document.getElementById( 'root' ) );
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={ domain }
      clientId={ clientId }
      redirectUri={ window.location.origin }
    >
      <Provider store={ store }>
        <AuthProvider>
          <Router>
            <Admin />
          </Router>
        </AuthProvider>
      </Provider>
    </Auth0Provider>


  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
