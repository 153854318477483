const INITIAL_STATE = {
	currentUser: null,
	users: [],
	usersError: null,
	userLoader: false,
};

export const userReducer = ( state = INITIAL_STATE, action ) =>
{
	switch ( action.type )
	{
		case "USER_UPDATE":
			console.log( "action.payload>>>", action.payload );
			return {
				...state,
				users: action.payload.users,
				userError: null,
				userLoader: false,
			};
		case "CURRENT_USER_UPDATE":
			console.log( action.payload.currentUser )
			return {
				...state,
				currentUser: action.payload.currentUser,
				userError: null,
				userLoader: false,
			}
		case "USER_LOADER":
			return {
				...state,
				userLoader: true,
				userError: null,
			}
		case "USER_ERROR":
			return {
				...state,
				userError: action.payload.error,
				userLoader: false,
			}
		default:
			return state;
	}
}

