/* eslint-disable no-undef */
/* eslint-disable import/no-anonymous-default-export */

export default ( editor, opts = {} ) =>
{
	const dc = editor.DomComponents;
	const defaultType = dc.getType( "default" );
	const defaultView = defaultType.view;

	dc.addType( opts.name, {
		droppable: false,
		editable: true,

		isComponent: ( el ) =>
		{
			if ( el.className && el.className.includes( "custom-button" ) )
			{
				return {
					type: opts.name,
				};
			}
		},
	} );
};
