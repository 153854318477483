import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import Loader from '../components/loader/Loader';
import useAuth from '../hooks/useAuth';

const PrivateRoute = ( { children, ...rest } ) =>
{
	const { user, isLoading } = useAuth()
	if ( isLoading )
	{
		return <Loader />
	}
	console.log( isLoading )

	return (
		<Route
			{ ...rest }
			render={ ( { location } ) =>
				user.email ? children : (
					<Redirect
						to={ {
							pathname: "/login",
							state: { from: location }
						} }
					/>
				)
			}
		>

		</Route>
	);
};

export default PrivateRoute;