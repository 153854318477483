const INITIAL_STATE = {
	plans: [],
	plansError: null,
	plansLoader: false,
};

export const subscriptionplansReducer = ( state = INITIAL_STATE, action ) =>
{
	switch ( action.type )
	{
		case "SUBSCRIPTION_PLAN_UPDATE":
			console.log( "action.payload>>>", action.payload );
			return {
				...state,
				plans: action.payload.plans,
				plansError: null,
				plansLoader: false,
			};
		case "SUBSCRIPTION_PLAN_LOADER":
			return {
				...state,
				plansLoader: true,
				plansError: null,
			}
		case "SUBSCRIPTION_PLAN_ERROR":
			return {
				...state,
				plansError: action.payload.error,
				plansLoader: false,
			}
		default:
			return state;
	}
}

