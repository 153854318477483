import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import "grapesjs/dist/css/grapes.min.css";
import axios from "axios";
import { connect, useDispatch } from "react-redux";
import Sidebar from '../../../components/editor/Sidebar'
import TopNav from '../../../components/editor/TopNav'
import geditorConfig from "../../../utilities/editor/geditor_config";
import "@google/model-viewer/dist/model-viewer";
import "bootstrap";
import './Editor.scss'
import { serverUrl } from '../../../config/ApiDetails';
import { Redirect, useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import ModelEditor from "../ModelEditor/ModelEditor";
import logo from '../../../assets/images/Xarwin-logo-dark.png'
import Features from "../../../components/editor/Features";
import { HiInformationCircle } from 'react-icons/hi'
import { getAllDefaultModels } from "../../../actions/Models";

const Editor = ( { getAllDefaultModels, showModelEditor, modelViewer, modelMaterial, admin, models } ) =>
{
	const [ editor, setEditor ] = useState( null );
	const { templateId } = useParams();
	const history = useHistory();
	const dispatch = useDispatch()

	console.log( showModelEditor )
	useEffect( () =>
	{
		console.log( admin )
		console.log( admin.length )
		if ( admin != null && admin.accessToken != null && serverUrl != null )
		{
			console.log( admin )
			console.log( admin.accessToken )
			console.log( "Fetching Project" )
			getAllDefaultModels()
		}
	}, [ admin ] );

	useEffect( () =>
	{
		const editor = geditorConfig( {
			templateId: templateId,
			serverUrl: serverUrl,
			admin: admin,
			history: history,
			admin_id: admin.uid,
			models: models,
		} );
		setEditor( editor );
	}, [ models ] )


	const showEditorHandler = () =>
	{
		console.log( editor )
		const modelComponent = editor.Pages.getAll()[ 0 ].getMainComponent().find( '.3dmodel' );
		console.log( modelComponent )

		var iframe = document.getElementsByClassName( "gjs-frame" )[ 0 ];
		const modelViewer1 = iframe.contentWindow.document.getElementsByClassName( "3dmodel" )
		if ( modelViewer1.length > 0 )
		{
			console.log( iframe )
			console.log( typeof modelViewer1 )
			console.log( modelViewer1 )
			dispatch( {
				type: "UPDATE_MODEL_VIEWER", payload: {
					modelViewer: modelViewer1[ 0 ].outerHTML,
					modelMaterial: null,
				}
			} )
			dispatch( { type: "SHOW_MODEL_EDITOR" } )
		}

	}

	const updateEditedModel = () =>
	{
		if ( editor != null )
		{
			console.log( 'Inside Here' )
			var iframe = document.getElementsByClassName( "gjs-frame" )[ 0 ];
			const modelViewerComponent = iframe.contentWindow.document.getElementsByClassName( "3dmodel" )[ 0 ]
			if ( modelViewerComponent.model !== null )
			{
				const material = modelViewerComponent.model.materials[ 0 ];
				material.pbrMetallicRoughness.setBaseColorFactor( modelMaterial.baseColorFactor )
				material.pbrMetallicRoughness.setRoughnessFactor( modelMaterial.roughness )
				material.pbrMetallicRoughness.setMetallicFactor( modelMaterial.metalness )
				material.setEmissiveFactor( modelMaterial.emissiveFactor )
			}
		}
	}

	return (
		<div className="model-presentation-editor">
			<div className="header">
				<div className="logo">
					<img src={ logo } alt="" />
					<h2 className="d-flex align-items-center">Editor</h2>
				</div>
				<div className="panel__devices"></div>
			</div>
			<div>
				<div
					className="sidenav d-flex flex-column position-fixed"
				>
					<div style={ { display: 'flex', alignItems: 'start' } }>
						<Sidebar showEditorHandler={ showEditorHandler } />
						<Features />
					</div>
				</div>
				<div
					className="main-content position-relative w-75 start-25"
				>
					<div className="editor-container d-flex">
						<div id="editor">

						</div>
						<div className="actions">
							<TopNav />
						</div>
					</div>
					<Modal
						show={ showModelEditor }
						fullscreen={ true }
						id='Model-Editor'
						dialogClassName="modal-90w"
						aria-labelledby="example-custom-modal-styling-title"
					>
						<Modal.Header>
							<Modal.Title id="example-custom-modal-styling-title">
								<div className="header">
									<div className="logo">
										<img src={ logo } alt="" />
									</div>
									<h2>Model-Editor</h2>
								</div>
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<ModelEditor />
						</Modal.Body>
						<Modal.Footer>
							<button className="modeleditorbutton" onClick={ () =>
							{
								updateEditedModel()
								dispatch( { type: "SHOW_MODEL_EDITOR" } )
							} }>Back to Editor</button>
						</Modal.Footer>
					</Modal>
				</div>
			</div>
		</div>
	);
}
const mapPropsToState = ( state ) =>
{
	console.log( state )
	return {
		showModelEditor: state?.modelEditor?.showModelEditor,
		modelViewer: state?.modelEditor?.modelViewer,
		modelMaterial: state?.modelEditor?.modelEditor?.Material,
		project: state?.modelEditor?.project,
		admin: state?.admin?.adminData,
		models: state?.model?.defaultModels,
	}
}

export default connect( mapPropsToState, { getAllDefaultModels } )( ( withRouter( Editor ) ) );

