import
{
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import './Analytics.css'

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	BarElement,
	Title,
	Tooltip,
	Legend
);


const Analytics = ( { analytics } ) =>
{
	console.log( analytics )
	console.log( analytics )
	const options = {
		responsive: true,
		bezierCurve: true,
		borderJoinStyle: 'round',
		borderWidth: 5,
		tooltips: {
			mode: 'index',
			intersect: false
		},
		hover: {
			mode: 'index',
			intersect: false
		},
		plugins: {
			legend: {
				position: 'top',
				align: 'end',
				labels: {
					boxWidth: 10,
					boxHeight: 10,
				},
			},
			title: {
				display: false,
			},
		},
		scales: {
			x: {
				grid: {
					display: false,
				},
			},
			y: {
				grid: {
					display: false,
				},
			},
		},
	};


	const data = {
		labels: analytics[ "dimensions" ],
		datasets: [
			{
				label: 'Analytics',
				data: analytics[ "metrics" ],
				borderColor: '#eee',
				backgroundColor: '#7980F5',
				lineTension: 1,
			},
		],
	};

	return (
		<div>
			<Bar options={ options } data={ data } />
		</div>
	);
};

export default Analytics;
