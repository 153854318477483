
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import "./AllUsers.css";
import { CSVLink } from 'react-csv';

const AllUsersPage = ( { users } ) =>
{

	const dispatch = useDispatch()
	const history = useHistory()
	const columns = [
		{
			field: "_id",
			headerName: "ID",
			width: 230
		},
		{
			field: "Name",
			headerName: "Name",
			width: 200
		},
		{
			field: "Email",
			headerName: "Email",
			width: 250
		},
		{
			field: "Project_Id",
			headerName: "Projects",
			width: 100,
			renderCell: ( params ) =>
			{
				return params?.row[ "Project_Id" ].length
			}
		},
		{
			field: "Models_Id",
			headerName: "Models",
			width: 100,
			renderCell: ( params ) =>
			{
				return params?.row[ "Model_Id" ].length
			}
		},
		{
			field: "Firebase_Id",
			headerName: "Firebase Id",
			width: 280
		},
	];

	const viewButtonHandler = ( user ) =>
	{
		history.push( `/users/${user?._id}` )
	}
	return (
		<div className="user_list">
			<div className='all-users'>
				{ users != null && users.length > 0 && <CSVLink className='download-report-button' data={ users } headers={ [
					{ label: "User Firebase ID", key: "Firebase_Id" },
					{ label: "User Name", key: "Name" },
					{ label: "User Email", key: "Email" },
					{ label: "createdAt", key: "createdAt" },
				] }>
					Download
				</CSVLink> }
				<div className="row">
					{

						users?.map( ( user, index ) => (

							<div className="col-md-4" key={ index }>
								<figure className="snip hover">
									<img src={ user?.profile ? user.profile : "https://imgs.search.brave.com/vU2ba85dA64ykRlQzQrFKyR_2xNlb7u1fCZJ6XEUPVc/rs:fit:820:434:1/g:ce/aHR0cHM6Ly93d3cu/cG5na2V5LmNvbS9w/bmcvZGV0YWlsLzIz/My0yMzMyNjc3X2lt/YWdlLTUwMDU4MC1w/bGFjZWhvbGRlci10/cmFuc3BhcmVudC5w/bmc" } alt="sample47" />
									<figcaption>
										<h2>{ user?.Name }</h2>
										<br />

										<h6><span>User Id:</span> { user?._id }</h6>
										<p>{ user?.Email }</p>
										<p><span>Projects:</span> { user ? user[ "Project_Id" ]?.length : 0 } <span>Models:</span> { user ? user[ "Model_Id" ]?.length : 0 }</p>
										{/* <p><span>Last Login On:</span> { user ? user[ "Last_Login_Time" ] !== undefined && user[ "Last_Login_Time" ] !== null ? user[ "Last_Login_Time" ]?.split( "T" )[ 0 ] : '--' : '--' }</p> */ }
										<div className="icons">
											<button className='btn btn-warning'
												onClick={
													() =>
														viewButtonHandler( user )
												}
											>View</button>
											{/* <Button variant="contained" color="error">Delete</Button> */ }
										</div>
									</figcaption>
								</figure>
							</div>

						) )
					}
				</div>
			</div>
		</div>
	)
}


export default AllUsersPage;