import Analytics from "../../components/dashboard/ProjectAnalytics/Analytics";
import { getAllProjectsAnalytics } from "../../actions/Analytics";
import Loader from "../../components/loader/Loader";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const Dashboard = ( { getAllProjectsAnalytics, analytics, dashboardLoader } ) =>
{
	const [ analyticsData, setAnalyticsData ] = useState( [] )
	useEffect( () =>
	{
		getAllProjectsAnalytics()
	}, [] )

	useEffect( () =>
	{
		console.log( analytics )
		console.log( analytics[ "dimensions" ] )
		console.log( analytics[ "metrics" ] )
		setAnalyticsData( analytics )
	}, [ analytics ] )


	return (
		<div>
			{
				dashboardLoader === false ? <div>
					<Analytics analytics={ analyticsData } />
				</div> : <Loader />
			}
		</div>
	);
};

const mapPropsToState = ( state ) =>
{
	return {
		analytics: state?.dashboard?.dashboardAnalytics,
		dashboardLoader: state?.dashboard?.dashboardLoader,
	}
}

export default connect( mapPropsToState, { getAllProjectsAnalytics } )( ( withRouter( Dashboard ) ) );



