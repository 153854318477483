import React, { useEffect, useState } from 'react'
import "@google/model-viewer/dist/model-viewer";
import './ModelEditor.css'
import { connect, } from 'react-redux';
import { Alert, Button, Form, InputGroup } from 'react-bootstrap';
import ModelFeatures from './ModelEditorFeatures/ModelFeatures';

const ModelEditor = ( { modelViewer, modelMaterial, tour } ) =>
{
	const [ showHotspot, setShowHotspot ] = useState( false )
	const [ showHotspotLabel, setShowHotspotLabel ] = useState( false );
	const [ points, setPoints ] = useState( null )
	const [ labelValue, setLabelValue ] = useState( '' )
	const [ existingHotspots, setExistingHotspots ] = useState( [] )


	const handleClick = ( event ) =>
	{
		try
		{
			const { clientX, clientY } = event;
			let hit = event.target.positionAndNormalFromPoint( clientX, clientY );
			console.log( hit )
			setPoints( hit )
			setShowHotspotLabel( true )
		} catch ( error )
		{
			console.log( error )
		}
	};

	const setHotspot = () =>
	{
		if ( points )
		{
			console.log( "Here" )
			setExistingHotspots( [] )
			const hotspotId = Math.random().toString( 36 ).replace( '0.', '' );
			const hotspot = `<button draggable="false" droppable="false" class="view-button" id=${hotspotId} slot=hotspot-${hotspotId} data-position="${getDataPosition( points )}" data-normal="${getDataNormal( points )}" droppable="false" draggable="true" data-visibility-attribute="visible" >${labelValue}</button>`;

			console.log( hotspot )
			document.getElementById( 'model-viewer' ).firstChild.innerHTML += hotspot

			setShowHotspot( false )
			setShowHotspotLabel( false )
		}
		setShowHotspotLabel( false )
	}

	const fetchHotspots = () =>
	{
		const hotspots = Array.from( document.getElementsByClassName( 'view-button' ) )
		console.log( hotspots )
		setExistingHotspots( hotspots )
	}

	const getDataPosition = ( annot ) =>
	{
		return `${annot.position.x} ${annot.position.y} ${annot.position.z}`;
	};

	const getDataNormal = ( annot ) =>
	{
		return `${annot.normal.x} ${annot.normal.y} ${annot.normal.z}`;
	};

	useEffect( () =>
	{
		console.log( modelViewer )
		document.getElementById( 'model-viewer' ).innerHTML = modelViewer
		console.log( document.getElementById( 'model-viewer' ).innerHTML )
		console.log( document.getElementById( 'model-viewer' ).firstChild )
		console.log( document.getElementById( 'model-viewer' ).firstChild )
		document.getElementById( 'model-viewer' ).firstChild.addEventListener( 'click', handleClick )
		console.log( document.getElementById( 'model-viewer' ).model )
	}, [] )


	return (
		<div >

			<div className='model-editor'>
				<div className='sidebar'>
					<ModelFeatures />
				</div>
				<div className="editor-view">
					<div className="" id="model-viewer"
					>

					</div>
					<div className="header">
						<div className="hotspot">
							<Button className="bs-button" variant="success" size="lg" onClick={ () => setShowHotspot( true ) }>Add Hotspot</Button>
							{
								showHotspot && <div>
									<p>Please click on the Model</p>
								</div>
							}
							{ showHotspot && showHotspotLabel && <Alert show={ true } variant="success">
								<Alert.Heading>HOTSPOT</Alert.Heading>
								<p>
									Hotspot Label
								</p>
								<hr />

								<InputGroup className="mb-3">
									<Form.Control
										placeholder="Hotpsot"
										id='hotspot-input'
										onChange={ ( e ) =>
										{
											console.log( "width" )
											setLabelValue( e.target.value )
										} }
									/>
								</InputGroup>
								<div className="d-flex justify-content-end">
									<Button variant="outline-success" onClick={ setHotspot }>
										Save
									</Button>
								</div>
							</Alert> }
							<Button className="bs-button" style={ { backgroundColor: "#23374D" } } variant="danger" size="lg" onClick={ fetchHotspots }>Delete Hotspot</Button>
							{
								existingHotspots.map( ( l1, index ) => (
									<div key={ index } onClick={ () =>
									{
										const temp = existingHotspots.filter( ( l ) =>
											l.id !== l1.id
										)
										console.log( temp )
										setExistingHotspots( temp )
										document.getElementById( l1.id ).remove()
									} }>
										<Button variant="contained" color="danger">{ l1.innerHTML } X</Button>
									</div>
								) )
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}


const mapStateToProps = ( state ) =>
{
	// console.log( state )
	console.log( document.getElementById( 'model-viewer' ) )
	return {
		modelEditor: state?.modelEditor,
		modelViewer: state?.modelEditor?.modelViewer,
		modelMaterial: state?.modelEditor?.modelMaterial,
		lighting: state?.modelEditor?.modelEditor?.Lighting,
	}
}

export default connect( mapStateToProps )( ModelEditor )

