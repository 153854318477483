/* eslint-disable import/no-anonymous-default-export */
export default ( editor, opts = {} ) =>
{
	const bm = editor.BlockManager;

	const styles = `
		<style>
		.custom-button {
			background-color: black;
			border-radius: 10px;
			padding: 10px;
			border: 1px solid black;
			width: fit-content;
			margin: auto auto auto auto;
		}
		.custom-button>a{
			text-decoration: none;
			color: white;
		}
		</style>
	`;
	bm.add( opts.name, {
		label: `
		<i class="fa fa-stop"></i>
		<br />
	  <div class="gjs-block-label">
		${opts.label}
	  </div> 
	  `,
		category: opts.category,
		content: `
		<div class="custom-button">
			<a href="">Anchor</a>
		</div>${styles}
		`,
	} );
};
