import axios from 'axios'
import Swal from 'sweetalert2';
import { serverUrl } from '../config/ApiDetails';

export const getAllProjects = () => ( dispatch, getState ) =>
{
	dispatch( { type: "PROJECT_LOADER" } )
	const admin = getState()?.admin?.adminData;
	console.log( admin )
	axios.get( `${serverUrl}/projects/getAll`, {
		headers: {
			'Authorization': "Bearer " + admin.accessToken
		}
	} ).then( ( res ) =>
	{
		console.log( res.data )
		const projects = res.data.projects
		if ( projects != null )
			dispatch( { type: "PROJECT_UPDATE", payload: { projects: projects } } )
	} )
		.catch( ( err ) =>
		{
			console.log( err )
			dispatch( { type: "PROJECT_ERROR", payload: { error: err } } )
		} )
}

export const getProjectById = ( projId, callback ) => ( dispatch, getState ) =>
{
	console.log( "Project Id" )
	dispatch( { type: "PROJECT_LOADER" } )
	const admin = getState()?.admin?.adminData;
	axios.get( `${serverUrl}/projects/${projId}`, {
		headers: {
			'Authorization': "Bearer " + admin.accessToken
		}
	} ).then( ( res ) =>
	{
		console.log( res )
		const project = res.data.project
		const model = project[ "Model_Id" ][ 0 ]
		dispatch( { type: "CURRENT_PROJECT_UPDATE", payload: { currentProject: project } } )
		dispatch( { type: "CURRENT_MODEL_UPDATE", payload: { currentModel: model } } )
	} )
		.catch( ( err ) =>
		{
			console.log( err )
		} )
	dispatch( { type: "PROJECT_LOADER" } )
}

export const updateProjectStatus = ( id, status, callback ) => ( dispatch, getState ) =>
{
	dispatch( { type: "PROJECT_LOADER" } )
	const admin = getState()?.admin?.adminData;
	console.log( admin.accessToken )
	console.log( status )
	axios.post( `${serverUrl}/projects/status`, {
		Project_Id: id,
		Status: status,
	}, {
		headers: {
			'Authorization': "Bearer " + admin.accessToken
		}
	} ).then( ( res ) =>
	{
		const project = res.data.project
		const model = project[ "Model_Id" ][ 0 ]
		console.log( project )
		console.log( model )
		Swal.fire( res.data.message );
		dispatch( { type: "CURRENT_PROJECT_UPDATE", payload: { currentProject: project } } )
		dispatch( { type: "CURRENT_MODEL_UPDATE", payload: { currentModel: model } } )
	} ).catch( ( err ) =>
	{
		console.log( err )
		Swal.fire( err.message );
		dispatch( { type: "PROJECT_LOADER" } )
	} )
}

export const deleteProject = ( id, history ) => ( dispatch, getState ) =>
{
	dispatch( { type: "PROJECT_LOADER" } )
	const admin = getState()?.admin?.adminData;
	dispatch( { type: "PROJECT_LOADER" } )
	axios.delete( `${serverUrl}/projects/${id}`, {
		headers: {
			'Authorization': "Bearer " + admin.accessToken
		}
	} )
		.then( ( res ) =>
		{
			Swal.fire( res.data.message )
			dispatch( { type: "PROJECT_LOADER" } )
			history.push( '/projects' )
		} )
		.catch( ( err ) =>
		{
			dispatch( { type: "PROJECT_LOADER" } )
			Swal.fire( err.message )
		} )

}