import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import './TemplateCard.css'

const TemplateCard = ( { id, templateName, templateCategory, onDelete } ) =>
{
	const history = useHistory()

	const onEdit = () =>
	{
		history.push( `/templates/editor/${id}` );
	};

	return (
		<div className='template-card-wrapper'>
			<h1>{ templateName }</h1>
			<br />
			<p>{ templateCategory }</p>
			<div>
				<button onClick={ () => onEdit() }>Edit</button>
				<button onClick={ () => onDelete( id ) }> Delete</button>
			</div>
		</div>
	);
};

export default TemplateCard;
