const INITIAL_STATE = {
	dashboardAnalytics: [],
	dashboardError: null,
	dashboardLoader: false,
};

export const dashboardReducer = ( state = INITIAL_STATE, action ) =>
{
	switch ( action.type )
	{
		case "DASHBOARD_UPDATE":
			console.log( "action.payload>>>", action.payload );
			return {
				...state,
				dashboardAnalytics: action.payload.analytics,
				dashboardError: null,
				dashboardLoader: false,
			};
		case "DASHBOARD_LOADER":
			return {
				...state,
				dashboardLoader: true,
				dashboardError: null,
			}
		case "DASHBOARD_ERROR":
			return {
				...state,
				dashboardError: action.payload.error,
				dashboardLoader: false,
			}
		default:
			return state;
	}
}

