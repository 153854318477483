const INITIAL_STATE = {
	userActivities: [],
	reportError: null,
	reportLoader: false,
};

export const reportsReducer = ( state = INITIAL_STATE, action ) =>
{
	switch ( action.type )
	{
		case "USER_ACTIVITY_UPDATE":
			console.log( "action.payload>>>", action.payload );
			return {
				...state,
				userActivities: action.payload.userActivities,
				reportError: null,
				reportLoader: false,
			};
		case "REPORT_LOADER":
			return {
				...state,
				reportLoader: !state.reportLoader,
				reportError: null,
			}
		case "REPORT_ERROR":
			return {
				...state,
				reportError: action.payload.error,
				reportLoader: false,
			}
		default:
			return state;
	}
}

