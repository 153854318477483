/* eslint-disable no-undef */
/* eslint-disable import/no-anonymous-default-export */

export default ( editor, opts = {} ) =>
{
	const dc = editor.DomComponents;
	const defaultType = dc.getType( "default" );
	const defaultView = defaultType.view;

	dc.addType( opts.name, {
		droppable: false,
		model: {
			defaults: {
				script: function ()
				{
					const script = document.createElement( 'script' );
					script.src = "https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js"
					script.type = 'module'
					document.body.appendChild( script )
				},
			},
		},
		isComponent: ( el ) =>
		{
			if ( el.className && el.className.includes( "model-viewer" ) )
			{
				return {
					type: opts.name,
				};
			}
		},
	} );
};
