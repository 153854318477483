import axios from "axios";
import
{
    getAuth,
    onAuthStateChanged,
    signOut,
    signInWithEmailAndPassword,
    updateProfile,
    GoogleAuthProvider,
    signInWithPopup,
    FacebookAuthProvider,
    createUserWithEmailAndPassword,
    onIdTokenChanged
} from "firebase/auth";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { serverUrl } from '../config/ApiDetails';

import initializeAuthentication from "../Firebase/firebase.init";


initializeAuthentication();


const useFirebase = () =>
{
    const [ admin, setAdmin ] = useState( {} )
    const auth = getAuth()
    const [ isLoading, setIsLoading ] = useState( true );
    const [ error, setError ] = useState( '' )
    const dispatch = useDispatch()

    const googleProvider = new GoogleAuthProvider();
    const facebookProvider = new FacebookAuthProvider();

    console.log( auth )

    //  user login with email and password

    const handleAdminLogin = ( email, password, history ) =>
    {
        signInWithEmailAndPassword( auth, email, password )
            .then( ( result ) =>
            {
                console.log( result.user );
                setAdmin( result.user );
                dispatch( {
                    type: "ADMIN_UPDATE", payload: {
                        admin: admin
                    }
                } )
                setError( '' )
                history.push( '/dashboard' )
            } )
            .catch( ( error ) =>
            {
                setError( error.message )
            } );
    };



    useEffect( () =>
    {
        const unsubscribed = onAuthStateChanged( auth, ( user ) =>
        {
            console.log( user )
            if ( user )
            {
                console.log( user )
                console.log( user.accessToken )
                setAdmin( user );
                dispatch( {
                    type: "ADMIN_UPDATE", payload: {
                        admin: user
                    }
                } )
            }
            else
            {
                setAdmin( {} );
                dispatch( {
                    type: "ADMIN_UPDATE", payload: {
                        admin: {}
                    }
                } )
                logOut();
            }
            setIsLoading( false )
            console.log( isLoading )
        } );

        return () => unsubscribed;
    }, [] )


    // logout
    const logOut = ( history ) =>
    {

        console.log( "Logout" )
        signOut( auth )
            .then( () =>
            {
                setAdmin( {} );
                history.push( "/login" )
            } )

    }

    return {
        auth,
        admin,
        handleAdminLogin,
        logOut,
        error,
        serverUrl,
        isLoading
    }
}

export default useFirebase;
