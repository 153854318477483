
import { DataGrid } from '@mui/x-data-grid';
import { useHistory } from 'react-router-dom';

import "./Report.css";
import { CSVLink } from "react-csv";

const UserReportPage = ( { report } ) =>
{
	const headers = [
		{ label: "User ID", key: "User_Id" },
		{ label: "User Firebase ID", key: "User_Firebase_Id" },
		{ label: "User Name", key: "User_Name" },
		{ label: "User Email", key: "User_Email" },
	];

	const columns = [
		{
			field: "User_Id",
			headerName: "User Id",
			width: 300
		},
		{
			field: "User_Firebase_Id",
			headerName: "User Firebase Id",
			width: 300
		},
		{
			field: "User_Name",
			headerName: "User Name",
			width: 300
		},
		{
			field: "User_Email",
			headerName: "User Email",
			width: 300
		},
	];

	return (
		<div className="report_list">
			<DataGrid
				className="dataGrid"
				rows={ report }
				getRowId={ ( row ) => row.User_Id }
				disableSelectionOnClick
				columns={ columns }
				pageSize={ 15 }
				autoHeight
			/>
			<br />
			<CSVLink className='download-report-button' data={ report } headers={ headers }>
				Download
			</CSVLink>
		</div>
	)
}


export default UserReportPage;