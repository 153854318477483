const INITIAL_STATE = {
	models: [],
	defaultModels: [],
	currentModel: null,
	modelsError: null,
	modelsLoader: false,
};

export const modelReducer = ( state = INITIAL_STATE, action ) =>
{
	switch ( action.type )
	{
		case "MODEL_UPDATE":
			console.log( "action.payload>>>", action.payload );
			return {
				...state,
				models: action.payload.models,
				modelsError: null,
				modelsLoader: false,
			};
		case "DEFAULT_MODEL_UPDATE":
			return {
				...state,
				defaultModels: action.payload.defaultModels,
				modelsError: null,
				modelsLoader: false,
			};
		case "CURRENT_MODEL_UPDATE":
			return {
				...state,
				currentModel: action.payload.currentModel,
				modelsError: null,
				modelsLoader: false,
			};
		case "MODEL_LOADER":
			return {
				...state,
				modelsLoader: !state.modelsLoader,
				modelsError: null,
			}
		case "MODEL_ERROR":
			return {
				...state,
				modelsError: action.payload.error,
				modelsLoader: false,
			}
		default:
			return state;
	}
}

