import { Button } from 'react-bootstrap'
import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import './ModelFeatures.css'
import { BsFillArrowDownSquareFill, BsFillArrowUpSquareFill, BsDownload } from 'react-icons/bs'

const ModelFeatures = ( { lighting, material, hotspot } ) =>
{
	const dispatch = useDispatch()
	const fonts = [ 'Poppins', 'Montserrat', 'Lato', 'Helvetica', 'Oswald', 'Times New Roman', 'Arial Black', 'Tahoma', 'Verdana', 'Courier New Courier', 'Open Sans', 'Great Vibes', 'Roboto', 'Noto Sans Mono' ]
	const [ chosenFont, setChosenFont ] = useState( '' )
	const [ openCategory, setOpenCategory ] = useState( 'lighting' )
	const [ isHotspotError, setIsHotspotError ] = useState( false )
	const [ animations, setAnimations ] = useState( [] )

	const toggleCategory = ( category ) =>
	{
		setOpenCategory( category )
	}


	const environmentImages = {
		"House": "https://xarwin-assets-space.fra1.digitaloceanspaces.com/Xarwin/Plain.hdr",
		"Lounge": "https://xarwin-assets-space.fra1.digitaloceanspaces.com/Xarwin/lythwood_lounge_4k.hdr",
		"Forest": "https://xarwin-assets-space.fra1.digitaloceanspaces.com/Xarwin/whipple_creek_regional_park_04_4k.hdr",
		"Sunrise": "https://xarwin-assets-space.fra1.digitaloceanspaces.com/Xarwin/spruit_sunrise_4k.hdr"
	}

	const animationHandler = () =>
	{
		toggleCategory( 'animations' )
		const modelviewer = document.getElementById( 'model-viewer' ).firstChild

		setAnimations( modelviewer.availableAnimations )

	}

	const materialHandler = ( control, value ) =>
	{
		const modelViewer = document.getElementById( 'model-viewer' ).firstChild;

		let modelMaterial = modelViewer.model.materials[ 0 ];
		if ( control === 'roughness' )
		{
			modelMaterial.pbrMetallicRoughness.setRoughnessFactor( value );
		} else if ( control === 'metalness' )
		{
			modelMaterial.pbrMetallicRoughness.setMetallicFactor( value );
		}
	}

	const colorHandler = ( control, color ) =>
	{
		const modelViewer = document.getElementById( 'model-viewer' ).firstChild;


		let modelMaterial = modelViewer.model.materials[ 0 ];
		console.log( modelMaterial )
		const r = parseInt( color.substr( 1, 2 ), 16 )
		const g = parseInt( color.substr( 3, 2 ), 16 )
		const b = parseInt( color.substr( 5, 2 ), 16 )
		if ( control === 'base' )
		{
			modelMaterial.pbrMetallicRoughness.setBaseColorFactor( [ r / 255, g / 255, b / 255 ] )
		}
		else if ( control === 'emissive' )
		{
			modelMaterial.setEmissiveFactor( [ r / 255, g / 255, b / 255 ] )
		}
	}

	const exportGLB = async () =>
	{
		const modelViewer = document.getElementById( 'model-viewer' ).firstChild;

		const glTF = await modelViewer.exportScene();
		const file = new File( [ glTF ], "export.glb" );
		const link = document.createElement( "a" );
		link.download = file.name;
		link.href = URL.createObjectURL( file );
		link.click();
	}

	return (
		<div className='features'>
			<div className='category'>
				<h3 onClick={ () => toggleCategory( 'lighting' ) }>Lighting { openCategory === 'lighting' ? <BsFillArrowUpSquareFill /> : <BsFillArrowDownSquareFill /> }</h3>
				<br />
				{ openCategory === 'lighting' && <div className="features-list">

					<div>
						<label htmlFor="shadow-intensity">Shadow-Intensity</label>
						<input type="range" name="" id="" min="0" max="2" step="0.01" value={ lighting.shadowIntensity } onChange={

							( e ) =>
							{

								document.getElementById( 'model-viewer' ).firstChild.setAttribute( 'shadow-intensity', e.target.value )
								dispatch( {
									type: "UPDATE_MODEL_EDITOR",
									payload: {
										Lighting: {
											...lighting,
											shadowIntensity: e.target.value
										},
										Material: material,
										Hotspot: hotspot,
									}
								} )
							}
						} />
					</div>
					<div>
						<label htmlFor="shadow-softness">Shadow-Softness</label>
						<input type="range" name="" id="shadow-softness" min="0" max="2" step="0.01" value={ lighting.shadowSoftness }
							onChange={
								( e ) =>
								{

									document.getElementById( 'model-viewer' ).firstChild.setAttribute( 'shadow-softness', e.target.value )
									dispatch( {
										type: "UPDATE_MODEL_EDITOR",
										payload: {
											Lighting: {
												...lighting,
												shadowSoftness: e.target.value
											},
											Material: material,
											Hotspot: hotspot,
										}
									} )
								} } />
					</div>
					<div>
						<label htmlFor="exposure">Exposure</label>
						<input type="range" name="" id="" min="0" max="2" step="0.01" value={ lighting.exposure }
							onChange={ ( e ) =>
							{

								document.getElementById( 'model-viewer' ).firstChild.setAttribute( 'exposure', e.target.value )
								dispatch( {
									type: "UPDATE_MODEL_EDITOR",
									payload: {
										Lighting: {
											...lighting,
											exposure: e.target.value
										},
										Material: material,
										Hotspot: hotspot,
									}
								} )
							} } />
					</div>
					<div className='d-flex flex-row'>
						<label htmlFor="ar-mode">Google AR model</label>
						&nbsp;
						<input
							type="checkbox"
							id="ar-mode"
							onChange={ ( e ) =>
							{
								if ( e.target.checked )
								{
									document.getElementById( 'model-viewer' ).firstChild.setAttribute( 'ar-modes', "scene-viewer webxr quick-look" )
								} else
								{
									document.getElementById( 'model-viewer' ).firstChild.removeAttribute( 'ar-modes' )
								}
							} }
						/>
					</div>
					<div>
						<label htmlFor="font">Font Family</label>
						<select
							name=""
							id=""
							value={ chosenFont }
							onChange={ ( e ) =>
							{
								var sCls = Array.from( document.getElementsByClassName( 'view-button' ) );
								if ( sCls.length > 0 )
								{
									setIsHotspotError( false )
									setChosenFont( e.target.value )
									console.log( e.target.value )
									console.log( sCls )
									for ( var hotspot in sCls )
									{
										console.log( sCls[ hotspot ] )
										sCls[ hotspot ].style.fontFamily = e.target.value;
									}
								} else
								{
									setIsHotspotError( true )
								}
							} }>
							{
								fonts.map( ( font, index ) => (
									<option key={ index } value={ font }>{ font }</option>
								) )
							}
						</select>
						{
							isHotspotError && <p>Create a hotspot!</p>
						}
					</div>

				</div> }
			</div>
			<div className='category'>
				<h3 onClick={ animationHandler }>Animations { openCategory === 'animations' ? <BsFillArrowUpSquareFill /> : <BsFillArrowDownSquareFill /> }</h3>
				<br />
				{ openCategory === 'animations' && <div className="features-list">
					<div>
						<select
							onChange={ ( e ) =>
							{
								document.getElementById( 'model-viewer' ).firstChild.setAttribute( 'animation-name', e.target.value )
							} }
						>
							<option value="none">None</option>
							{
								animations.map( ( animation, index ) => (
									<option key={ index } value={ animation }>{ animation }</option>
								) )
							}
						</select>
					</div>
				</div> }
			</div>
			<div className='category'>
				<h3 onClick={ () => toggleCategory( 'environment' ) }>Environment { openCategory === 'environment' ? <BsFillArrowUpSquareFill /> : <BsFillArrowDownSquareFill /> }</h3>
				<br />
				{ openCategory === 'environment' && <div className="features-list">
					<div>
						<label>Environment Image</label>
						<select
							onChange={ ( e ) =>
							{
								if ( e.target.value === 'none' )
								{
									document.getElementById( 'model-viewer' ).firstChild.removeAttribute( 'environment-image' )
								} else
								{
									document.getElementById( 'model-viewer' ).firstChild.setAttribute( 'environment-image', e.target.value )
								}

							} }
						>
							<option value="none">None</option>
							{
								Object.keys( environmentImages ).map( ( key, index ) => (
									<option key={ index } value={ environmentImages[ key ] }>{ key }</option>
								) )
							}
						</select>
					</div>
					<div>
						<label>Skybox Image</label>
						<select
							onChange={ ( e ) =>
							{
								if ( e.target.value === 'none' )
								{
									document.getElementById( 'model-viewer' ).firstChild.removeAttribute( 'skybox-image' )
								} else
								{
									document.getElementById( 'model-viewer' ).firstChild.setAttribute( 'skybox-image', e.target.value )
								}
							} }
						>
							<option value="none">None</option>
							{
								Object.keys( environmentImages ).map( ( key, index ) => (
									<option key={ index } value={ environmentImages[ key ] }>{ key }</option>
								) )
							}
						</select>
					</div>
				</div> }
			</div>
			<div className="category">
				<h3 onClick={ () => toggleCategory( 'material' ) }>Material { openCategory === 'material' ? <BsFillArrowUpSquareFill /> : <BsFillArrowDownSquareFill /> }</h3>
				{ openCategory === 'material' && <div className="features-list">
					<div>
						<p>Metalness: <span id="metalness-value"></span></p>
						<input
							id="metalness"
							type="range"
							min="0"
							max="1"
							step="0.01"
							// value={ material[ "metalness" ] }
							onChange={ ( e ) =>
							{
								materialHandler( "metalness", e.target.value )
							} }
						/>
					</div>
					<div>
						<p>Roughness: <span id="roughness-value"></span></p>
						<input id="roughness"
							type="range"
							min="0"
							max="1"
							step="0.01"
							// value={ material[ "roughness" ] }
							onChange={ ( e ) =>
							{
								materialHandler( "roughness", e.target.value )
							} }
						/>
					</div>
					<div>
						<p className='d-flex justify-content-between'>Base Color: <input type="color" name="" id="" onChange={ ( e ) => colorHandler( "base", e.target.value ) } /></p>
						<p className='d-flex justify-content-between'>Emissive Factor: <input type="color" name="" id="" onChange={ ( e ) => colorHandler( "emissive", e.target.value ) } /></p>
					</div>
				</div> }
			</div>
			<div className='category'>
				<h3 onClick={ exportGLB }>Export <BsDownload /></h3>
				<br />
			</div>
			<div className="actions" style={ { textAlign: "center" } }>
				<Button
					id='saveModelButton'
					size="lg"
					onClick={ () =>
					{
						document.getElementById( "saveModelButton" ).innerHTML = "Saving..."
						const modelViewer = document.getElementById( 'model-viewer' );
						let modelMaterial = modelViewer.firstChild.model.materials[ 0 ];
						dispatch( {
							type: "UPDATE_MODEL_EDITOR",
							payload: {
								Material: {
									...material,
									metalness: modelMaterial.pbrMetallicRoughness.metallicFactor,
									roughness: modelMaterial.pbrMetallicRoughness.roughnessFactor,
									baseColorFactor: modelMaterial.pbrMetallicRoughness.baseColorFactor,
									emissiveFactor: modelMaterial.emissiveFactor
								},
								Lighting: lighting,
								Hotspot: hotspot
							}
						} )

						dispatch( {
							type: 'UPDATE_MODEL_VIEWER',
							payload: {
								modelViewer: modelViewer.innerHTML,
								modelMaterial: material
							}
						} )
						setTimeout( () =>
						{
							document.getElementById( "saveModelButton" ).innerHTML = "Saved";
							setTimeout( () =>
							{
								document.getElementById( "saveModelButton" ).innerHTML = "Save";
							}, 1000 )
						}, 1000 )
					} }
				>Save</Button>
			</div>
		</div >
	)
}

const mapStateToProps = ( state ) =>
{
	return {
		lighting: state?.modelEditor?.modelEditor?.Lighting,
		material: state?.modelEditor?.modelEditor?.Material,
		hotspot: state?.modelEditor?.modelEditor?.Hotspot,
		modelViewer: state?.modelEditor?.modelViewer
	}
}

export default connect( mapStateToProps )( ModelFeatures )

