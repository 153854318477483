const INITIAL_STATE = {
	templates: [],
	templatesError: null,
	templatesLoader: false,
};

export const templatesReducer = ( state = INITIAL_STATE, action ) =>
{
	switch ( action.type )
	{
		case "TEMPLATE_UPDATE":
			console.log( "action.payload>>>", action.payload );
			return {
				...state,
				templates: action.payload.templates,
				templatesError: null,
				templatesLoader: false,
			};
		case "TEMPLATE_LOADER":
			return {
				...state,
				templatesLoader: !state.templatesLoader,
				templatesError: null,
			}
		case "TEMPLATE_ERROR":
			return {
				...state,
				templatesError: action.payload.error,
				templatesLoader: false,
			}
		default:
			return state;
	}
}

