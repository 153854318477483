import { combineReducers } from "redux";
import { userReducer } from './UserReducer'
import { sidebarToggleReducer } from './SidebarReducer'
import { adminReducer } from "./AdminReducer";
import { projectReducer } from "./ProjectReducer";
import { modelReducer } from './ModelReducer'
import { dashboardReducer } from "./DashboardReducer";
import { subscriptionplansReducer } from "./SubscriptionPlans";
import { editorReducer } from "./EditorReducer"
import { templatesReducer } from "./TemplateReducer";
import { reportsReducer } from "./Reports";

const appReducer = combineReducers( {
	users: userReducer,
	sidebar: sidebarToggleReducer,
	admin: adminReducer,
	modelEditor: editorReducer,
	project: projectReducer,
	model: modelReducer,
	dashboard: dashboardReducer,
	plans: subscriptionplansReducer,
	template: templatesReducer,
	report: reportsReducer
} );

const rootReducer = ( state, action ) =>
{
	if ( action.type === "USER_LOGOUT" )
	{
		state = undefined;
	} else
	{
		return appReducer( state, action );
	}
};

export default rootReducer;