// import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducer from '../reducers/'
import { createStore, applyMiddleware, compose } from 'redux';


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore( reducer, composeEnhancers(
	applyMiddleware( thunk )
) );


// const store = createStore( reducer,  state, applyMiddleware( thunk ));

store.subscribe( () =>
{
} );

export default store;