import './Admin.css';
import TopBar from './components/topnav/Topbar';
import Sidebar from './components/sidebar/Sidebar';
import Dashboard from './pages/Dashboard/Dashboard';

//Projects
import Projects from './pages/Projects/Projects';
import ProjectDetail from './pages/Projects/ProjectDetail';

//Users
import Users from './pages/Users/Users';
import EditUser from './pages/Users/EditUser';

//Models
import Models from './pages/Models/Models';
import PageNotFound from './pages/PageNotFound';
import ViewModel from './pages/Models/ViewModel';

//Auth
import Login from './pages/Auth/Login/LoginPage';
import Register from './pages/Auth/Register/RegisterPage';

// Subscriptions
import Subscriptions from './pages/Subscriptions/AllPlans/Plans';
import CreateSubscription from './pages/Subscriptions/NewPlans/CreatePlan';


// Editor
import Editor from './pages/Editor/PresentationEditor/Editor'


import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from './setup/PrivateRoute';

// Template
import CreateTemplate from './pages/Templates/CreateTemplate/CreateTemplate';
import Templates from './pages/Templates/AllTemplates/Templates';

// Report
import UserReport from './pages/Reports/UserActivityReport/UserActivity'

function Admin ()
{
  return (
    <div style={ { minHeight: "100vh" } }>

      <Switch>
        {/* Auth Routes */ }
        <Route exact path="/login" component={ Login } />
        {/* <Route exact path="/register" component={ Register } /> */ }

        {/* Editor Routes */ }
        <Route exact path='/templates/editor/:templateId' component={ Editor } />
        <div className="admin">
          <Route>
            <div className='header'>
              <Sidebar />
            </div>
            <div className="main-page-content">
              <TopBar />
              <div className='pages'>
                <PrivateRoute exact path="/dashboard" component={ Dashboard } />

                {/* Project Routes */ }
                <PrivateRoute exact path="/projects" component={ Projects } />
                <PrivateRoute exact path="/projects/:projId" component={ ProjectDetail } />

                {/* Model Routes */ }
                <PrivateRoute exact path="/models" component={ Models } />
                <PrivateRoute exact path="/models/:modelId" component={ ViewModel } />

                {/* User Routes */ }
                <PrivateRoute exact path="/users" component={ Users } />
                <PrivateRoute exact path="/users/:userId" component={ EditUser } />

                {/* Subscription Plan Routes */ }
                <PrivateRoute exact path="/plans" component={ Subscriptions } />
                <PrivateRoute exact path="/plans/create" component={ CreateSubscription } />

                {/* Template Routes */ }
                <PrivateRoute exact path="/templates" component={ Templates } />
                <PrivateRoute exact path="/templates/create" component={ CreateTemplate } />

                {/* User Report */ }
                <PrivateRoute exact path="/report/users" component={ UserReport } />

                {/* <Route component={ PageNotFound } /> */ }
              </div>
            </div>
          </Route>
        </div>
      </Switch>
    </div>

  );
}

export default Admin;
