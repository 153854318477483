import axios from 'axios';
import Swal from 'sweetalert2';
import { serverUrl } from '../config/ApiDetails';

export const getAllPlans = () => async ( dispatch, getState ) =>
{
	dispatch( { type: "SUBSCRIPTION_PLAN_LOADER" } )
	const admin = getState()?.admin?.adminData;
	console.log( admin )
	const token = await admin?.getIdToken()
	console.log( token )
	axios.get( `${serverUrl}/plans/getAll`, {
		headers: {
			'Authorization': "Bearer " + token
		}
	} ).then( ( res ) =>
	{
		console.log( res )
		const plans = res.data.plans
		dispatch( { type: "SUBSCRIPTION_PLAN_UPDATE", payload: { plans: plans } } )
	} )
		.catch( ( err ) =>
		{
			console.log( err )
			dispatch( { type: "SUBSCRIPTION_PLAN_LOADER" } )
		} )
}

export const CreatePlan = ( planDetails, history ) => async ( dispatch, getState ) =>
{
	console.log( planDetails )
	dispatch( { type: "SUBSCRIPTION_PLAN_LOADER" } )
	const admin = getState()?.admin?.adminData;
	console.log( admin )
	axios.post( `${serverUrl}/plans/create`, planDetails, {
		headers: {
			'Authorization': "Bearer " + admin.accessToken
		}
	} ).then( ( res ) =>
	{
		dispatch( { type: "SUBSCRIPTION_PLAN_LOADER" } )
		history.push( "/plans" )
	} )
		.catch( ( err ) =>
		{
			console.log( err )
			Swal.fire( { title: err.message } )
			dispatch( { type: "SUBSCRIPTION_PLAN_LOADER" } )
		} )
}