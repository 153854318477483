import grapesjs from "grapesjs";
import $ from 'jquery'
import axios from 'axios'
import
{
	addEditorCommand,
	deviceManager,
	panels,
	scripts,
	selectorManager,
	storageSetting,
	styleManager,
	styles,
	traitManager,
} from "./geditor_utils";
import gjsBlockBasic from 'grapesjs-blocks-basic'
import grapesjsBlockBootstrap from "grapesjs-blocks-bootstrap4";
import grapesjsStyleBg from "grapesjs-style-bg";
import gjsBlocksFlexbox from 'grapesjs-blocks-flexbox'
import gjsCodeEditor from 'grapesjs-component-code-editor'
import 'grapesjs-component-code-editor/dist/grapesjs-component-code-editor.min.css';



// Do not remove
import modelviewerComponent from '../../components/editor/plugins/modelviewer'
import customButton from '../../components/editor/plugins/button/'



const geditorConfig = ( { templateId, serverUrl, admin, history, admin_id, models } ) =>
{

	console.log( admin )
	// console.log( models )

	$( ".panel__devices" ).html( "" );
	$( ".panel__basic-actions" ).html( "" );
	$( ".panel__editor" ).html( "" );
	$( "#blocks" ).html( "" );
	$( "#styles-container" ).html( "" );
	$( "#trait-container" ).html( "" );

	// Content for Preview
	const navbar = $( "#navbar" );
	const mainContent = $( "#main-content" );
	const panelTopBar = $( "#main-content > .navbar-light" );

	const editor = grapesjs.init( {
		container: "#editor",
		blockManager: {
			appendTo: "#blocks",
		},
		styleManager: styleManager,
		traitManager: traitManager,
		selectorManager: selectorManager,
		panels: panels,
		deviceManager: deviceManager,
		assetManager: {
			// Upload endpoint, set `false` to disable upload, default `false`
			upload: `${serverUrl}/projects/assets/upload/${admin_id}`,
			headers: {
				"Authorization": "Bearer " + admin.accessToken
			},
			// The name used in POST to pass uploaded files, default: `'files'`
			uploadName: 'files',
		},
		storageManager: storageSetting(),
		canvas: {
			styles: styles,
			scripts: scripts,
		},
		plugins: [
			gjsBlockBasic,
			"modelviewerComponent",
			"customButton",
			grapesjsBlockBootstrap,
			grapesjsStyleBg,
			gjsBlocksFlexbox,
			gjsCodeEditor
		],
		pluginsOpts: {
			[ gjsBlockBasic ]: {
				blocks: [ 'column1', 'column2', 'column3', 'column3-7', 'text', 'link', 'image' ]
			},
			modelviewerComponent: {
				models: models
			},
			customButton: {},
			[ grapesjsBlockBootstrap ]: {
				blockCategories: {
					forms: false,
					layout: false,
					media: false,
					components: false,
				}
			},
			grapesjsStyleBg: {},
			[ gjsBlocksFlexbox ]: {},
			[ gjsCodeEditor ]: {
				closedState: { pn: '100%', cv: '100%' },
				openState: { pn: '100%', cv: '100%' }
			}
		},
	} );

	editor.setComponents( `
	<div class="project">
		<div class="heading">
			<h2 class="title">New project 1</h2>
			<p>
				Enable your customers to visualize your Product right in their
				environment using Immersive AR without even having to download an
				app. Press on 'View in AR'
			</p>
		</div>
	</div>
  `)

	editor.setStyle( `
	@media only screen and (max-width: 800px) {
		.project {
		  flex-direction: column-reverse;
		}
	  }
	  #ar-button {
		background-image: url("https://user-images.githubusercontent.com/66217883/175517729-3f051f60-7d25-45df-9b41-f57065a17c6a.png");
		background-repeat: no-repeat;
		background-size: 20px 20px;
		background-position: 12px 50%;
		background-color: #fff;
		position: absolute;
		right: 5%;
		transform: translateX(-50%);
		bottom: 10px;
		padding: 0px 16px 0px 40px;
		font-family: Roboto Regular, Helvetica Neue, sans-serif;
		font-size: 14px;
		color: #4285f4;
		height: 36px;
		line-height: 36px;
		border-radius: 18px;
		border: 1px solid #dadce0;
	  }

	  #ar-button:active {
		background-color: #e8eaed;
	  }

	  #ar-button:focus {
		outline: none;
	  }

	  #ar-button:focus-visible {
		outline: 1px solid #4285f4;
	  }

	  @keyframes circle {
		from {
		  transform: translateX(-50%) rotate(0deg) translateX(50px)
			rotate(0deg);
		}
		to {
		  transform: translateX(-50%) rotate(360deg) translateX(50px)
			rotate(-360deg);
		}
	  }

	  @keyframes elongate {
		from {
		  transform: translateX(100px);
		}
		to {
		  transform: translateX(-100px);
		}
	  }

	  model-viewer > #ar-prompt {
		position: absolute;
		left: 50%;
		bottom: 175px;
		animation: elongate 2s infinite ease-in-out alternate;
		display: none;
	  }

	  model-viewer[ar-status="session-started"] > #ar-prompt {
		display: block;
	  }

	  model-viewer > #ar-prompt > img {
		animation: circle 4s linear infinite;
	  }
  
	  .project {
		display: flex;
		justify-content: space-around;
		margin: 0 auto;
	  }

	  .3dmodel{
		background-color: #eee;
		width: 100%;
	  }

	  .loader{
		position: absolute;
		top: 0;
		bottom: 0%;
		left: 0;
		right: 0%;
		z-index: 99;
		opacity:0.7;
		display:none;
		background: lightgrey url('https://xarwin-assets-space.fra1.digitaloceanspaces.com/Xarwin/Xarwin-Preloader.gif') center center no-repeat;
	  }

	  .gjs-row{
		display: table;
		padding-top: 10px;
		padding-right: 10px;
		padding-bottom: 10px;
		padding-left: 10px;
		width: 100%;
	  }

  `)

	const bm = editor.BlockManager
	console.log( editor.Blocks.getAll() )
	console.log( editor.BlockManager.getAll() )

	// const pn = editor.Panels;
	// const panelViews = pn.addPanel( {
	// 	id: 'views',
	// 	visible: true,
	// } );
	// pn.addButton( 'myNewPanel', {
	// 	id: 'myNewButton',
	// 	className: 'someClass',
	// 	command: 'someCommand',
	// 	attributes: { title: 'Some title' },
	// 	active: false,
	// } )
	// panelViews.get( 'buttons' ).add( [ {
	// 	attributes: {
	// 		title: 'Open Code'
	// 	},
	// 	className: 'fa fa-arrow',
	// 	command: 'open-code',
	// 	togglable: false, //do not close when button is clicked again
	// 	id: 'open-code'
	// } ] );
	// const basic = [ 'text', 'header', 'paragraph', 'image', ]
	// const layout = [ 'column1', 'column2', 'column3', 'column3-7', ]
	// const blocks = bm.getAll()
	// blocks.models.map( ( block ) =>
	// {
	// 	if ( layout.includes( block.id ) )
	// 	{
	// 		block.set( 'category', 'Layout' )
	// 	}
	// 	else if ( basic.includes( block.id ) )
	// 	{
	// 		block.set( 'category', 'Basic' )
	// 	}
	// } )
	// blocks.models.sort( ( a, b ) =>
	// {
	// 	let fa = a.get( 'category' ),
	// 		fb = b.get( 'category' );

	// 	if ( fa < fb )
	// 	{
	// 		return -1;
	// 	}
	// 	if ( fa > fb )
	// 	{
	// 		return 1;
	// 	}
	// 	return 0;
	// } )
	// bm.render( blocks.models )
	// console.log( bm.get( 'flexbox' ) )


	addEditorCommand( editor, serverUrl, templateId, admin, history );
	editor.Storage.add( 'remote', {
		async load ()
		{
			console.log( "LOADINGG DATAAA" )
			const res = await axios.get( `${serverUrl}/templates/${templateId}/content` )
			console.log( res )
			return res.data.data
		},

		async store ( data )
		{
			$( ".saveDb" ).html( "Saving..." );
			$( ".saveDb" ).prop( "disabled", true )
			$( ".publish" ).css( "color", "red" )
			console.log( " STORINGG DATA" )
			console.log( data )
			await axios.patch( `${serverUrl}/templates/${templateId}/content`, { data } );
			$( ".saveDb" ).prop( "disabled", false )
			$( ".saveDb" ).html( "Save" );
		},
	} );

	editor.on( 'component:selected', ( component ) =>
	{
		$( '#editor-tabs li' ).children().removeClass( 'active' );
		$( '#style-tab' ).addClass( 'active' );
		$( '.tab-content' ).children().removeClass( 'active show' );
		$( '#style' ).addClass( 'active show' );
	} )

	editor.on( 'asset:upload:start', () =>
	{
		$( ".gjs-am-assets" ).append( "<div class=\"uploading\">Uploading,Please wait..</div>" )
	} );

	editor.on( 'asset:upload:end', () =>
	{
		$( ".gjs-am-assets>.uploading" ).remove()
	} );

	editor.on( "run:preview", () =>
	{
		console.log( "It will trigger when we click on preview icon" );
		// This will be used to hide border
		editor.stopCommand( "sw-visibility" );
		// This will hide the sidebar view
		navbar.removeClass( "sidebar" );
		// This will make the main-content to be full width
		mainContent.removeClass( "main-content" );

		// This will hide top panel where we have added the button
		panelTopBar.addClass( "d-none" );
	} );
	editor.on( "stop:preview", () =>
	{
		// This event is reverse of the above event.
		console.log( "It will trigger when we click on cancel preview icon" );
		editor.runCommand( "sw-visibility" );
		navbar.addClass( "sidebar" );
		mainContent.addClass( "main-content" );
		panelTopBar.removeClass( "d-none" );
	} );

	setTimeout( () =>
	{
		let categories = editor.BlockManager.getCategories();
		categories.each( ( category ) => category.set( "open", false ) );
	}, 1000 );
	return editor;
};

export default geditorConfig