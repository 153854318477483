
import { DataGrid } from '@mui/x-data-grid';
import { useHistory } from 'react-router-dom';

import "./AllModels.css";

const AllModelsPage = ( { models } ) =>
{
	const history = useHistory()
	const columns = [
		{
			field: "_id",
			headerName: "ID",
			width: 230
		},
		{
			field: "User_Id",
			headerName: "User Firebase Id",
			width: 280
		},
		{
			field: "Model_Name",
			headerName: "Name",
			width: 200
		},
		{
			field: "createdAt",
			headerName: "Created At",
			width: 120,
			renderCell: ( params ) =>
			{
				return <p>{ params.row.createdAt?.split( "T" )[ 0 ] }</p>
			}
		}, {
			field: "action",
			headerName: "Action",
			width: 120,
			renderCell: ( params ) =>
			{
				return <button className='btn btn-outline-secondary' onClick={ () => history.push( `models/${params.row._id}` ) }>View</button>
			}
		},
	];

	return (
		<div className="models_list">
			<DataGrid
				className="dataGrid"
				rows={ models }
				getRowId={ ( row ) => row._id }
				disableSelectionOnClick
				columns={ columns }
				pageSize={ 15 }
				autoHeight
			/>
		</div>
	)
}


export default AllModelsPage;