import axios from 'axios'
import Swal from 'sweetalert2'
import { serverUrl } from '../config/ApiDetails'

export const getAllUsers = () => async ( dispatch, getState ) =>
{
	console.log( "Users action" )
	dispatch( { type: "USER_LOADER" } )
	const admin = getState().admin.adminData
	console.log( admin )
	await axios.get( `${serverUrl}/users/getAll`, {
		headers: {
			'Authorization': "Bearer " + admin.accessToken
		}
	} ).then( ( res ) =>
	{
		console.log( res )
		const users = res.data.users
		console.log( users )
		dispatch( { type: "USER_UPDATE", payload: { users: users } } )
		return users
	} ).catch( ( err ) =>
	{
		console.log( err )
		dispatch( { type: "USER_ERROR", payload: { error: err } } )
		return []
	} )
}

export const getUserById = ( userId ) => async ( dispatch, getState ) =>
{
	dispatch( { type: "USER_LOADER" } )
	const admin = getState().admin.adminData
	axios.get( `${serverUrl}/users/${userId}`, {
		headers: {
			'Authorization': "Bearer " + admin.accessToken
		}
	} ).then( ( res ) =>
	{
		const user = res.data.user
		dispatch( {
			type: "CURRENT_USER_UPDATE", payload: {
				currentUser: user
			}
		} )
	} )
		.catch( ( err ) =>
		{
			dispatch( { type: "USER_LOADER" } )
			console.log( err )
		} )
}

export const updateUserInfo = ( userInfo, userId ) => ( dispatch, getState ) =>
{
	dispatch( { type: "USER_LOADER" } )
	const admin = getState().admin.adminData
	axios.post( `${serverUrl}/users/update/${userId}`, userInfo, {
		headers: {
			'Authorization': 'Bearer ' + admin.accessToken, 'Content-Type': 'multipart/form-data'
		},
	} ).then( ( res ) =>
	{
		const user = res.data.user
		dispatch( {
			type: "CURRENT_USER_UPDATE", payload: {
				currentUser: user
			}
		} )
	} ).catch( ( err ) =>
	{
		dispatch( { type: "USER_LOADER" } )
		console.log( err )
	} )
}

export const banUser = ( userId, banStatus ) => ( dispatch, getState ) =>
{
	dispatch( { type: "USER_LOADER" } )
	const admin = getState().admin.adminData
	axios.post( `${serverUrl}/users/ban/${userId}`, {
		banStatus: banStatus,
	}, {
		headers: {
			'Authorization': "Bearer " + admin.accessToken,
		},
	} ).then( ( res ) =>
	{
		const user = res.data.user
		dispatch( {
			type: "CURRENT_USER_UPDATE",
			payload: {
				currentUser: user
			}
		} )
	} ).catch( ( err ) =>
	{
		dispatch( {
			type: "CURRENT_USER_UPDATE", payload: {
				currentUser: null
			}
		} )
		console.log( err )
	} )
}


export const deleteUser = ( userId, history ) => ( dispatch, getState ) =>
{
	dispatch( { type: "USER_LOADER" } )
	const admin = getState().admin.adminData
	axios.delete( `${serverUrl}/users/${userId}`, {
		headers: {
			'Authorization': "Bearer " + admin.accessToken,
		}
	} ).then( ( res ) =>
	{
		Swal.fire( res.data.message )
		history.push( '/users' )
	} ).catch( ( err ) =>
	{
		Swal.fire( 'Not Deleted!' )
	} )
}