import React, { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ViewProject from '../../components/projects/ViewProjectComponents/View';
import { deleteProject, getProjectById, updateProjectStatus } from '../../actions/Projects';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ViewModel from '../../components/models/ViewModelComponent/View';
import Loader from '../../components/loader/Loader';



const ProjectDetail = ( { getProjectById, updateProjectStatus, deleteProject, currentProject, projectLoader } ) =>
{
	const [ value, setValue ] = useState( 0 );
	const [ project, setProject ] = useState( null )
	const { projId } = useParams()
	const history = useHistory()
	const handleChange = ( event, newValue ) =>
	{
		setValue( newValue );
	};

	useEffect( () =>
	{
		console.log( "first" )
		getProjectById( projId )
	}, [] )

	useEffect( () =>
	{
		setProject( currentProject )
	}, [ currentProject ] )


	const statusHandler = ( id, status ) =>
	{
		updateProjectStatus( id, status );
	}

	const deleteHandler = ( id ) =>
	{
		deleteProject( id, history )
	}


	return (
		<div style={ { height: "100%" } }>
			{ projectLoader === false ? project ? <div>
				<div>
					<Tabs
						defaultActiveKey="project"
						id="fill-tab-example"
						className="project-tabs mb-3"
						fill
					>
						<Tab eventKey="project" title="Project">
							<ViewProject />
							<div className="actions">
								{ project?.Status?.toLowerCase() === 'active' ? <button
									className='btn'
									onClick={ () => statusHandler( project?._id, 'inactive' ) }
								>
									Disable Project</button> : <button
										className='btn'
										onClick={ () => statusHandler( project?._id, 'active' ) }
									>
									Activate Project</button> }
								<button
									className='btn btn-danger'
									onClick={ () => deleteHandler( project?._id ) }
								>
									Delete Project</button>
							</div>
						</Tab>
						<Tab eventKey="model" title="Model">
							<ViewModel />
						</Tab>
					</Tabs>
				</div>
			</div> : "Project" : <Loader /> }
		</div>
	);
}

const mapPropsToState = ( state ) =>
{
	return {
		projectLoader: state?.project?.projectsLoader,
		currentProject: state?.project?.currentProject,
	}
}

export default connect( mapPropsToState, { getProjectById, updateProjectStatus, deleteProject } )( ( withRouter( ProjectDetail ) ) );


