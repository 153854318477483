import logo from "../../assets/images/Logo-Name.png";

import { TiHome, TiFlash, TiCog } from 'react-icons/ti';
import { RiBarChart2Fill, RiLayoutMasonryFill } from 'react-icons/ri'
import { HiOutlineDocumentReport } from 'react-icons/hi'
import { FaUsers } from 'react-icons/fa'
import { BsViewList } from "react-icons/bs";
import { IoIosLogOut, } from "react-icons/io";
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import './Sidebar.css'
import { useSelector } from "react-redux";
import useAuth from "../../hooks/useAuth";

const Sidebar = () =>
{
	const history = useHistory();
	const { logOut } = useAuth();
	// const location = useLocation();
	// get a screen size and set false on mobile screen

	const admin = useSelector( ( state ) => state?.admin.adminData )

	const [ isOpen, setIsOpen ] = useState( false );
	const pathName = "Dashboard"

	useEffect( () =>
	{
		let isMobile = window.innerWidth < 768;
		if ( isMobile )
		{
			setIsOpen( false );
		} else
		{
			setIsOpen( true );
		}
	}, [] );
	return (
		<div className="sidebarWrapper">
			<div className="topHolder">
				<img src={ logo } alt='Logo Xarwin' />
				<div className="mobileMenu" onClick={ () => setIsOpen( !isOpen ) }>
					<span />
					<span />
					<span />
				</div>
			</div>
			{ isOpen && (
				<div className="menus">
					<div className="menuItem" onClick={ () => history.push( '/dashboard' ) } active>
						<TiHome className="icon" size="1.8em" />
						Dashboard
					</div>
					<div className="menuItem" onClick={ () => history.push( '/projects' ) } active>
						<TiFlash className="icon" size="1.8em" /> All Projects
					</div>
					<div className="menuItem" onClick={ () => history.push( '/models' ) } active>
						<RiBarChart2Fill className="icon" size="1.8em" />
						All Models
					</div>
					<div className="menuItem" onClick={ () => history.push( '/users' ) } active>
						<FaUsers className="icon" size="1.8em" />
						All Users
					</div>
					<div className="menuItem" onClick={ () => history.push( '/plans' ) } active>
						<BsViewList className="icon" size="1.8em" />
						Plans
					</div>
					<div className="menuItem" onClick={ () => history.push( '/templates' ) } active>
						<RiLayoutMasonryFill className="icon" size="1.8em" />
						All Templates
					</div>
					<div className="menuItem" onClick={ () => history.push( '/report/users' ) } active>
						<HiOutlineDocumentReport className="icon" size="1.8em" />
						Reports
					</div>
					<div className="menuItem" onClick={ () => history.push( '/settings' ) } active>
						<TiCog className="icon" size="1.8em" />
						Settings
					</div>
					{ <div className="menuItem" onClick={ () => logOut( history ) }>
						<IoIosLogOut className="icon" size="1.8em" />
						Logout
					</div> }
				</div>
			) }
		</div>
	);
};

export default Sidebar;
