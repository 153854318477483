import axios from 'axios'
import Swal from 'sweetalert2';
import { serverUrl } from '../config/ApiDetails';

export const getAllModels = () => ( dispatch, getState ) =>
{
	dispatch( { type: "MODEL_LOADER" } )
	const admin = getState()?.admin?.adminData;
	console.log( admin )
	axios.get( `${serverUrl}/models/getAll`, {
		headers: {
			'Authorization': "Bearer " + admin.accessToken
		}
	} ).then( ( res ) =>
	{
		const models = res.data.models
		dispatch( { type: "MODEL_UPDATE", payload: { models: models } } )
	} )
		.catch( ( err ) =>
		{
			console.log( err )
			dispatch( { type: "MODEL_ERROR", payload: { error: err } } )
		} )
}

export const getAllDefaultModels = () => ( dispatch, getState ) =>
{
	console.log( "model default" )
	dispatch( { type: "MODEL_LOADER" } )
	const admin = getState()?.admin?.adminData;
	console.log( admin )
	axios.get( `${serverUrl}/models/default`, {
		headers: {
			'Authorization': "Bearer " + admin.accessToken
		}
	} ).then( ( res ) =>
	{
		const models = res.data.models
		dispatch( { type: "DEFAULT_MODEL_UPDATE", payload: { defaultModels: models } } )
	} )
		.catch( ( err ) =>
		{
			console.log( err )
			dispatch( { type: "MODEL_ERROR", payload: { error: err } } )
		} )
}

export const getModelById = ( id, callback ) => ( dispatch, getState ) =>
{
	dispatch( { type: "MODEL_LOADER" } )
	const admin = getState()?.admin?.adminData;
	dispatch( { type: "MODEL_LOADER" } )
	axios.get( `${serverUrl}/models/${id}`, {
		headers: {
			'Authorization': "Bearer " + admin.accessToken
		}
	} ).then( ( res ) =>
	{
		const model = res.data.model
		dispatch( { type: "CURRENT_MODEL_UPDATE", payload: { currentModel: model } } )
	} )
		.catch( ( err ) =>
		{
			console.log( err )
			dispatch( { type: "MODEL_LOADER" } )
		} )
}

export const deleteModel = ( id, callback ) => ( dispatch, getState ) =>
{
	dispatch( { type: "MODEL_LOADER" } )
	const admin = getState()?.admin?.adminData;
	dispatch( { type: "MODEL_LOADER" } )
	axios.delete( `${serverUrl}/models/${id}`, {
		headers: {
			'Authorization': "Bearer " + admin.accessToken
		}
	} )
		.then( ( res ) =>
		{
			Swal.fire( res.data.message )
			dispatch( { type: "CURRENT_MODEL_UPDATE", payload: { currentModel: null } } )
		} )
		.catch( ( err ) =>
		{
			Swal.fire( err.message )
			dispatch( { type: "MODEL_LOADER" } )

		} )
}