import React from 'react'
import loader from '../../assets/gif/loader.gif'


const styles = {
	loader: {
		margin: "0 40%",
		height: "80px",
		width: "80px",
	}
}

const Loader = () =>
{
	return (
		<div>
			<img src={ loader } style={ styles.loader } />
		</div>
	)
}

export default Loader