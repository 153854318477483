import React from 'react'
import { useEffect, useState } from 'react'
import './Plans.css'
import { getAllPlans } from '../../../actions/Plans'
import Loader from '../../../components/loader/Loader'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PlanCard from '../../../components/plans/Plancard/PlanCard'

const AllPlans = ( { getAllPlans, plansLoader, plans } ) =>
{
	const [ allPlans, setAllPlans ] = useState( [] )
	useEffect( () =>
	{
		getAllPlans();
	}, [] )

	useEffect( () =>
	{
		setAllPlans( plans )
	}, [ plans ] )


	return (
		<div>
			{
				plansLoader === false ? <div>
					<div className="plans">
						<div className='container'>
							<div className='row' container spacing={ 2 }>
								{
									allPlans.map( ( plan, index ) => (
										<div className='col-md-4'>
											<PlanCard key={ index } plan={ plan } index={ index } />
										</div>
									) )
								}
							</div>
						</div>
					</div>
				</div> : <Loader />
			}
		</div>
	)
}

const mapPropsToState = ( state ) =>
{
	return {
		plans: state?.plans?.plans,
		plansLoader: state?.plans?.plansLoader,
	}
}

export default connect( mapPropsToState, { getAllPlans } )( ( withRouter( AllPlans ) ) );

