import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getAllModels } from '../../actions/Models'
import { getAllProjects } from '../../actions/Projects'
import { getAllUsers } from '../../actions/Users'
import StyledTextField from '../styles/global.styles'
import './Filter.css'


const Filter = ( { data, category, getAllUsers, getAllProjects, getAllModels } ) =>
{
	// const [ id, setId ] = useState( null )
	const [ filteredData, setFilteredData ] = useState( data )
	useEffect( () =>
	{
		console.log( "Data" )
		console.log( data )
		setFilteredData( data )
	}, [] )
	const dispatch = useDispatch()

	const [ filterValues, setFilterValues ] = useState( {
		'Id': '',
		'Name': '',
		'Status': '',
		'Date': '',
	} )
	const filterByIdHandler = ( id ) =>
	{
		console.log( data )
		console.log( "Id Handler" )
		console.log( filteredData )
		console.log( id )
		setFilterValues( { ...filterValues, Id: id } )
		const filterData = filteredData.filter( ( element ) => element._id.includes( id ) )
		console.log( filterData )
		setFilteredData( filterData )
		console.log( filteredData )
	}
	const filterByNameHandler = ( name ) =>
	{
		setFilterValues( { ...filterValues, Name: name } );
		const key = category === "users" ? "Name" : category === "projects" ? "Project_Name" : "Model_Name";
		console.log( key )
		console.log( filteredData )
		const filterData = filteredData.filter( ( element ) => element[ key ].toLowerCase().includes( name.toLowerCase() ) )
		console.log( filterData )
		setFilteredData( filterData )
		console.log( filteredData )
	}
	const filterByDateHandler = ( date ) =>
	{
		console.log( date )
		setFilterValues( { ...filterValues, Date: date } );
		console.log( filteredData )
		const filterData = filteredData.filter( ( element ) => element.createdAt.split( "T" )[ 0 ] === date )
		console.log( filterData )
		setFilteredData( filterData )
		console.log( filteredData )
	}
	const filterButtonHandler = () =>
	{
		console.log( filteredData )
		if ( category === "users" )
		{
			dispatch( { type: "USER_UPDATE", payload: { users: filteredData } } )
		} else if ( category === "projects" )
		{
			dispatch( { type: "PROJECT_UPDATE", payload: { projects: filteredData } } )
		} else if ( category === "models" )
		{
			dispatch( { type: "MODEL_UPDATE", payload: { models: filteredData } } )
		}
	}
	const filterResetHandler = () =>
	{
		setFilteredData( data )
	}
	const filterClearHandler = () =>
	{
		console.log( data )
		if ( category === "users" )
		{
			getAllUsers()
		} else if ( category === "projects" )
		{
			getAllProjects()
		} else if ( category === "models" )
		{
			getAllModels()
		}
	}
	return (
		<div className='filter'>
			<h3>Filter By</h3>

			<div>
				<h2>Filter By ID</h2>
				<StyledTextField
					variant="outlined"
					fullWidth
					label='ID'
					value={ filterValues[ 'Id' ] }
					onChange={ ( e ) => filterByIdHandler( e.target.value ) }
				/>
			</div>

			<div>
				<h2>Filter By Name</h2>
				<StyledTextField
					variant="outlined"
					fullWidth
					label='Name'
					placeholder='Please enter name'
					value={ filterValues[ "Name" ] }
					onChange={ ( e ) => filterByNameHandler( e.target.value ) }
				/>
			</div>

			<div>
				<h2>Filter By Date</h2>
				<StyledTextField
					type="date"
					variant="outlined"
					fullWidth
					placeholder='Please enter Id'
					value={ filterValues[ "Date" ] }
					onChange={ ( e ) => filterByDateHandler( e.target.value ) }
				/>
			</div>
			<br />
			<br />
			<div className='actions'>
				<button
					className='btn btn-lg btn-success'
					onClick={ filterButtonHandler }
				>Filter</button>
				<button
					className='btn btn-lg btn-danger'
					color="error"
					onClick={ filterResetHandler }
				>Reset</button>
				<button
					className='btn btn-lg btn-primary'
					style={ { backgroundColor: "#5E5CCD" } }
					onClick={ filterClearHandler }
				>Clear</button>
			</div>
		</div>
	)
}

const mapPropsToState = ( state ) =>
{
	return {
		usersList: state?.users?.users,
		projects: state?.project?.projects,
		models: state?.model?.models,
	}
}

export default connect( mapPropsToState, { getAllUsers, getAllModels, getAllProjects } )( ( withRouter( Filter ) ) )