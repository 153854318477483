const local = {
	serverUrl: 'http://localhost:3001/admin'
}

const dev = {
	serverUrl: 'https://admindev.xarwin.com/api/admin'
}

const admin = {
	serverUrl: 'https://admin.xarwin.com/api/admin'
}

export const serverUrl = process.env.REACT_APP_STAGE === 'production' ? admin.serverUrl : ( process.env.REACT_APP_STAGE === 'dev' ? dev.serverUrl : local.serverUrl )

// export const serverUrl = local.serverUrl