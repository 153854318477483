
import './global.styles.css'
import React from 'react'

const StyledTextField = ( { ...props } ) =>
{
	return (
		<input
			className="input-box"
			{ ...props } />
	)
}

export default StyledTextField

// export const StyledTextField = withStyles( {
// 	root: {
// 		fontSize: '2rem',
// 		'& label.Mui-focused': {
// 			color: '#131d40',
// 		},
// 		'& .MuiInput-underline:after': {
// 			borderBottomColor: 'green',
// 		},
// 		'& .MuiOutlinedInput-root': {
// 			'& fieldset': {
// 				borderColor: 'black',
// 			},
// 			'&:hover fieldset': {
// 				borderColor: 'red',
// 			},
// 			'&.Mui-focused fieldset': {
// 				borderColor: '#131d40',
// 			},
// 		},
// 		'& .MuiInputBase-input': {
// 			fontSize: '1.2rem !important',
// 		}
// 	},
// } )( TextField );
