import React, { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../../../components/loader/Loader';

import { getWeeklyUserActivityReport, getMonthlyUserActivityReport } from '../../../actions/Analytics';
import UserReportPage from '../../../components/reports/UserActivityReport/Report';



const UserActivityPage = ( { getWeeklyUserActivityReport, userActivities, reportLoader } ) =>
{
	const [ value, setValue ] = useState( 0 );
	const [ userReport, setUserReport ] = useState( null )

	useEffect( () =>
	{
		if ( value == 0 )
		{
			getWeeklyUserActivityReport()
		} else
		{
			getMonthlyUserActivityReport()
		}
	}, [ value ] )

	useEffect( () =>
	{
		setUserReport( userActivities )
	}, [ userActivities ] )


	return (
		<div style={ { height: "100%" } }>
			{ reportLoader === false ? ( userReport !== undefined && userReport !== null && userReport.length > 0 ? <div>
				<div>
					<Tabs
						defaultActiveKey="week"
						id="fill-tab-example"
						className="report-tabs mb-3"
						fill
					>
						<Tab
							eventKey="week"
							title="Weekly"
							onClick={ () => setValue( 0 ) }
						>
							<UserReportPage report={ userReport } />
						</Tab>
						<Tab
							eventKey="month"
							title="Monthly"
							onClick={ () => setValue( 1 ) }
						>
							<UserReportPage report={ userReport } />
						</Tab>
					</Tabs>
				</div>
			</div> : "Report" ) : <Loader /> }
		</div>
	);
}

const mapPropsToState = ( state ) =>
{
	return {
		reportLoader: state?.report?.reportLoader,
		userActivities: state?.report?.userActivities
	}
}

export default connect( mapPropsToState, { getWeeklyUserActivityReport, getMonthlyUserActivityReport } )( ( withRouter( UserActivityPage ) ) );


