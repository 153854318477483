import React, { useEffect, useState } from 'react'
import AllUsersPage from '../../components/users/AllUserComponent/AllUsers'
import { getAllUsers } from '../../actions/Users'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from '../../components/loader/Loader'
import Filter from '../../components/filter/Filter';

const Users = ( { getAllUsersFunction, usersList, userLoader } ) =>
{

	const [ data, setData ] = useState( [] );
	const [ showFilterOptions, setShowFilterOptions ] = useState( false )

	useEffect( () =>
	{
		getAllUsersFunction();
		// eslint-disable-next-line
	}, [] )


	useEffect( () =>
	{
		console.log( usersList );
		setData( usersList )
		setShowFilterOptions( false )
	}, [ usersList ] )


	return (
		<div style={ { height: "100%" } }>
			{ userLoader === false ?
				<div style={ { position: "relative" } }>
					<AllUsersPage users={ data } />
					<div onClick={ () => setShowFilterOptions( true ) } className='filter-box'>Filter</div>
					{ showFilterOptions && <div className="filter-tab">
						<Filter data={ data } category={ "users" } />
					</div> }
				</div> : <Loader /> }
		</div>
	)
}

const mapPropsToState = ( state ) =>
{
	return {
		usersList: state?.users?.users,
		userLoader: state?.users?.userLoader,
	}
}

export default connect( mapPropsToState, { getAllUsersFunction: getAllUsers } )( ( withRouter( Users ) ) );

