import axios from 'axios';
import { serverUrl } from '../config/ApiDetails';

export const getAllProjectsAnalytics = () => ( dispatch, getState ) =>
{
	dispatch( { type: "DASHBOARD_LOADER" } )
	const admin = getState()?.admin?.adminData;
	console.log( admin )
	axios.get( `${serverUrl}/analytics/projects`, {
		headers: {
			'Authorization': "Bearer " + admin.accessToken
		}
	} ).then( ( res ) =>
	{
		console.log( res )
		const analytics = res.data.analytics
		dispatch( { type: "DASHBOARD_UPDATE", payload: { analytics: analytics } } )
	} )
		.catch( ( err ) =>
		{
			console.log( err )
			dispatch( { type: "DASHBOARD_LOADER" } )
		} )
}


export const getWeeklyUserActivityReport = () => ( dispatch, getState ) =>
{
	dispatch( { type: "REPORT_LOADER" } )
	const admin = getState()?.admin?.adminData;
	console.log( admin )
	axios.get( `${serverUrl}/analytics/users/week`, {
		headers: {
			'Authorization': "Bearer " + admin.accessToken
		}
	} ).then( ( res ) =>
	{
		console.log( res )
		const userActivities = res.data.userActivities
		dispatch( { type: "USER_ACTIVITY_UPDATE", payload: { userActivities: userActivities } } )
	} )
		.catch( ( err ) =>
		{
			console.log( err )
			dispatch( { type: "REPORT_LOADER" } )
		} )
}

export const getMonthlyUserActivityReport = () => ( dispatch, getState ) =>
{
	dispatch( { type: "REPORT_LOADER" } )
	const admin = getState()?.admin?.adminData;
	console.log( admin )
	axios.get( `${serverUrl}/analytics/users/month`, {
		headers: {
			'Authorization': "Bearer " + admin.accessToken
		}
	} ).then( ( res ) =>
	{
		console.log( res )
		const userActivities = res.data.userActivities
		dispatch( { type: "USER_ACTIVITY_UPDATE", payload: { userActivities: userActivities } } )
	} )
		.catch( ( err ) =>
		{
			console.log( err )
			dispatch( { type: "REPORT_LOADER" } )
		} )
}