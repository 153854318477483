const INITIAL_STATE = {
	projects: [],
	currentProject: null,
	projectsError: null,
	projectsLoader: false,
};

export const projectReducer = ( state = INITIAL_STATE, action ) =>
{
	switch ( action.type )
	{
		case "PROJECT_UPDATE":
			console.log( "action.payload>>>", action.payload );
			return {
				...state,
				projects: action.payload.projects,
				projectsError: null,
				projectsLoader: false,
			};
		case "CURRENT_PROJECT_UPDATE":
			return {
				...state,
				currentProject: action.payload.currentProject,
				projectsError: null,
				projectsLoader: false,
			};
		case "PROJECT_LOADER":
			return {
				...state,
				projectsLoader: !state.projectsLoader,
				projectsError: null,
			}
		case "PROJECT_ERROR":
			return {
				...state,
				projectsError: action.payload.error,
				projectsLoader: false,
			}
		default:
			return state;
	}
}

