import React from 'react'
import { useState } from 'react'
import './CreatePlan.scss'
import { makeStyles } from 'tss-react/mui';
import { CreatePlan } from '../../../actions/Plans';
import Loader from '../../../components/loader/Loader';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';

const classes = {
	inputBox: {
		display: 'flex',
		width: "100%",
	},
	inputField: {
		width: '50%',
		margin: '10px',
	},
	checkbox: {
		margin: 0,
	}
}

const CreatePlanPage = ( { CreatePlan, plansLoader } ) =>
{
	const history = useHistory()
	const [ planDetails, setPlanDetails ] = useState( {
		name: "",
		month_price: "",
		year_price: "",
		views: "Unlimited",
		webAR_experience: "",
		storage: "",
		multiple_models: false,
		watermark: true,
		templates: true,
		domain: false,
		analytics: true,
		community_support: true,
		dedicated_support: false,
		no_code: true,
	} )

	const createPlanHandler = () =>
	{
		CreatePlan( planDetails, history )
	}

	return (
		<div>
			<div className="new-plan-header">
				<h2>New Plan</h2>
			</div>
			<div className="plan-form">
				<div className={ "input-box " + classes.inputBox }>
					<div className={ "form-control " + classes.inputField } style={ { width: "100%" } }>
						<label className='form-label' htmlFor="component-outlined">Plan Name</label>
						<input
							required
							placeholder="PREMIUM"
							value={ planDetails[ "name" ] }
							onChange={ ( e ) =>
							{
								setPlanDetails( { ...planDetails, name: e.target.value } )
							} }
							label="Plan Name"
						/>
					</div>
					{/* <div className={"form-control "+ classes.inputField }>
						<label className='form-label' htmlFor="component-outlined">Item ID</label>
						<input
							required
							placeholder="PREMIUM_MONTHLY"
							value={ planDetails[ "item_id" ] }
							onChange={ ( e ) =>
							{
								setPlanDetails( { ...planDetails, item_id: e.target.value } )
							} }
							label="Item ID"
						/>
					</div> */}
				</div>
				<div className={ "input-box " + classes.inputBox }>
					<div className={ "form-control " + classes.inputField }>
						<label className='form-label' htmlFor="component-outlined">Monthly Price</label>
						<input
							required
							placeholder="1500"
							value={ planDetails[ "month_price" ] }
							type='number'
							onChange={ ( e ) =>
							{
								setPlanDetails( { ...planDetails, month_price: e.target.value } )
							} }
							label="Price"
						/>
					</div>
					<div className={ "form-control " + classes.inputField }>
						<label className='form-label' htmlFor="component-outlined">Annual Price</label>
						<input
							required
							placeholder="1500"
							value={ planDetails[ "year_price" ] }
							type='number'
							min="1"
							onChange={ ( e ) =>
							{
								setPlanDetails( { ...planDetails, year_price: e.target.value } )
							} }
							label="Annual Price"
						/>
					</div>
				</div>
				<div className={ "input-box " + classes.inputBox }>
					<div className={ "form-control " + classes.inputField }>
						<label className='form-label' htmlFor="component-outlined">Views</label>
						<input
							required
							readOnly
							placeholder="Views"
							value={ planDetails[ "views" ] }
							onChange={ ( e ) =>
							{
								setPlanDetails( { ...planDetails, views: e.target.value } )
							} }
							label="Views"
						/>
					</div>
					<div className={ "form-control " + classes.inputField }>
						<label className='form-label' htmlFor="component-outlined">WebAR Experience</label>
						<input
							required
							placeholder="Number"
							type='number'
							min="1"
							value={ planDetails[ "webAR_experience" ] }
							onChange={ ( e ) =>
							{
								setPlanDetails( { ...planDetails, webAR_experience: e.target.value } )
							} }
							label="WebAR Experience"
						/>
					</div>
					<div className={ "form-control " + classes.inputField }>
						<label className='form-label' id="multiple_models">Multiple Models</label>
						<select
							className="form-select"
							value={ planDetails[ "multiple_models" ] === true ? true : false }
							labelId="multiple_models"
							label="Multiple Models"
							onChange={ ( e ) =>
							{
								setPlanDetails( { ...planDetails, multiple_models: e.target.value } )
							} }
						>
							<option value={ true }>Yes</option>  <option value={ false }>No</option>
						</select>
					</div>
				</div>
				<div className={ "input-box " + classes.inputBox }>
					<div className={ "form-control " + classes.inputField }>
						<label className='form-label' htmlFor="component-outlined">Storage</label>
						<input
							required
							placeholder="(In MB)"
							value={ planDetails[ "storage" ] }
							type='number'
							min="1"
							onChange={ ( e ) =>
							{
								setPlanDetails( { ...planDetails, storage: e.target.value } )
							} }
							label="Storage"
						/>
					</div>
					<div className={ "form-control " + classes.inputField }>
						<label className='form-label' id="watermark">Watermark</label>
						<select
							className="form-select"
							value={ planDetails[ "watermark" ] === true ? true : false }
							labelId="watermark"
							label="Watermark"
							onChange={ ( e ) =>
							{
								setPlanDetails( { ...planDetails, watermark: e.target.value } )
							} }
						>
							<option value={ true }>Yes</option>  <option value={ false }>No</option>
						</select>
					</div>
				</div>
				<div className={ "input-box " + classes.inputBox }>
					<div className={ "form-control " + classes.inputField }>
						<label className='form-label' id="templates">Templates</label>
						<select
							className='form-select'
							value={ planDetails[ "templates" ] === true ? true : false }
							labelId="templates"
							label="Templates"
							onChange={ ( e ) =>
							{
								setPlanDetails( { ...planDetails, templates: e.target.value } )
							} }
						>
							<option value={ true }>Yes</option>  <option value={ false }>No</option>
						</select>
					</div>
					<div className={ "form-control " + classes.inputField }>
						<label className='form-label' id="domain">Domain</label>
						<select
							className='form-select'
							value={ planDetails[ "domain" ] === true ? true : false }
							labelId="domain"
							label="Domain"
							onChange={ ( e ) =>
							{
								setPlanDetails( { ...planDetails, domain: e.target.value } )
							} }
						>
							<option value={ true }>Yes</option>  <option value={ false }>No</option>
						</select>
					</div>
				</div>
				<div className={ "input-box " + classes.inputBox }>
					<div className={ "form-control " + classes.inputField }>
						<label className='form-label' id="analytics">Analytics</label>
						<select
							className='form-select'
							value={ planDetails[ "analytics" ] === true ? true : false }
							labelId="analytics"
							label="Analytics"
							onChange={ ( e ) =>
							{
								setPlanDetails( { ...planDetails, analytics: e.target.value } )
							} }
						>
							<option value={ true }>Yes</option>  <option value={ false }>No</option>
						</select>
					</div>
					<div className={ "form-control " + classes.inputField }>
						<label className='form-label' id="community_support">Community Support</label>
						<select
							className='form-select'
							value={ planDetails[ "community_support" ] === true ? true : false }
							labelId="community_support"
							label="Community Support"
							onChange={ ( e ) =>
							{
								setPlanDetails( { ...planDetails, community_support: e.target.value } )
							} }
						>
							<option value={ true }>Yes</option>  <option value={ false }>No</option>
						</select>
					</div>
				</div>
				<div className={ "input-box " + classes.inputBox }>
					<div className={ "form-control " + classes.inputField }>
						<label className='form-label' id="dedicated_support">Dedicated Support</label>
						<select
							className='form-select'
							value={ planDetails[ "dedicated_support" ] === true ? true : false }
							labelId="dedicated_support"
							label="Dedicated Support"
							onChange={ ( e ) =>
							{
								setPlanDetails( { ...planDetails, dedicated_support: e.target.value } )
							} }
						>
							<option value={ true }>Yes</option>  <option value={ false }>No</option>
						</select>
					</div>
					<div className={ "form-control " + classes.inputField }>
						<label className='form-label' id="no_code">No-Code</label>
						<select
							className='form-select'
							value={ planDetails[ "no_code" ] === true ? true : false }
							labelId="no_code"
							label="No-Code"
							readOnly
							onChange={ ( e ) =>
							{
								setPlanDetails( { ...planDetails, no_code: e.target.value } )
							} }
						>
							<option value={ true }>Yes</option>  <option value={ false }>No</option>
						</select>
					</div>
				</div>
			</div>
			{ plansLoader === false ? <div className="actions">
				<button
					className='btn btn-secondary'
					onClick={ createPlanHandler }
				>Create Plan</button>
			</div> : <Loader /> }
		</div>
	)
}

const mapPropsToState = ( state ) =>
{
	return {
		plansLoader: state?.plans?.plansLoader,
	}
}

export default connect( mapPropsToState, { CreatePlan } )( ( withRouter( CreatePlanPage ) ) );

