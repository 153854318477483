const INITIAL_STATE = {
	editor: [],
	showModelEditor: false,
	editorError: null,
	editorLoader: false,
	modelViewer: '',
	modelMaterial: null,
	showSidebar: true,
	showPresentationViewer: false,
	modelEditor: {
		"Lighting": {
			exposure: "1",
			shadowIntensity: "1",
			shadowSoftness: "1",
		},
		"Hotspot": false,
		"Material": {
			roughness: "0.5",
			metalness: "0.5",
			baseColorFactor: null,
			emissiveFactor: null,
		},
	},
	project: null,
};

export const editorReducer = ( state = INITIAL_STATE, action ) =>
{
	switch ( action.type )
	{
		case "SHOW_MODEL_EDITOR":
			console.log( "action.payload>>>", action.payload );
			return {
				...state,
				showModelEditor: !state.showModelEditor,
				editorError: null,
				editorLoader: false,
			};
		case "PROJECT_UPDATE":
			return {
				...state,
				project: action.payload.project,
			};
		case "TOGGLE_SIDEBAR":
			return {
				...state,
				showSidebar: !state.showSidebar,
			};
		case "CLOSE_SIDEBAR":
			return {
				...state,
				showSidebar: false,
			}
		case "OPEN_PRESENTATION_VIEWER":
			return {
				...state,
				showPresentationViewer: true,
			}
		case "CLOSE_PRESENTATION_VIEWER":
			return {
				...state,
				showPresentationViewer: false,
			}
		case "UPDATE_MODEL_EDITOR":
			console.log( action.payload.Lighting )
			return {
				...state,
				modelEditor: {
					...state.modelEditor,
					Lighting: action.payload.Lighting,
					Material: action.payload.Material,
					Hotspot: action.payload.Hotspot,
				},
				modelViewer: action.payload.modelViewer
			}
		case "UPDATE_MODEL_VIEWER":
			console.log( action.payload.modelMaterial )
			return {
				...state,
				modelViewer: action.payload.modelViewer,
				modelMaterial: action.payload.modelMaterial,
			}
		case "EDITOR_LOADER":
			return {
				...state,
				editorLoader: !state.editorLoader
			}
		default:
			return state;
	}
}

