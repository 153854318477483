
import { DataGrid } from '@mui/x-data-grid';
import { useHistory } from 'react-router-dom';

import "./AllProjects.css";
import { CSVLink } from 'react-csv';

const AllProjectsPage = ( { projects } ) =>
{
	const history = useHistory()

	const columns = [
		{
			field: "_id",
			headerName: "Project Id",
			width: 230
		},
		{
			field: "User_Id",
			headerName: "User Firebase Id",
			width: 280
		},
		{
			field: "Project_Name",
			headerName: "Project Name",
			width: 200
		},
		{
			field: "Draft",
			headerName: "Draft",
			width: 100
		},
		{
			field: "Status",
			headerName: "Status",
			width: 100
		},
		{
			field: "createdAt",
			headerName: "Created At",
			width: 120,
			renderCell: ( params ) =>
			{
				return <p>{ params.row.createdAt?.split( "T" )[ 0 ] }</p>
			}
		},
		{
			field: "action",
			headerName: "Action",
			width: 120,
			renderCell: ( params ) =>
			{
				return <button className='btn btn-outline-secondary' onClick={ () => history.push( `projects/${params.row._id}` ) }>View</button>
			}
		},
	];

	return (
		<div className="project_list">

			<DataGrid
				className="dataGrid"
				rows={ projects }
				getRowId={ ( row ) => row._id }
				disableSelectionOnClick
				columns={ columns }
				pageSize={ 15 }
				autoHeight
			/>
			<CSVLink className='download-report-button' data={ projects } headers={ [
				{ label: "Project ID", key: "_id" },
				{ label: "User Firebase ID", key: "User_Id" },
				{ label: "User Name", key: "User_Info.Name" },
				{ label: "User Email", key: "User_Info.Email" },
				{ label: "Project Name", key: "Project_Name" },
				{ label: "Project URL", key: "Url" },
				{ label: "createdAt", key: "createdAt" },
			] }>
				Download
			</CSVLink>
		</div>
	)
}


export default AllProjectsPage;