import React, { useEffect, useState } from 'react'
import { getAllModels } from '../../actions/Models'
import Loader from '../../components/loader/Loader'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import AllModels from '../../components/models/AllModelsComponent/AllModels'
import Filter from '../../components/filter/Filter'

const Models = ( { getAllModels, modelsList, modelLoader } ) =>
{
	const [ data, setData ] = useState( [] );
	const [ showFilterOptions, setShowFilterOptions ] = useState( false )

	useEffect( () =>
	{
		getAllModels();
		// eslint-disable-next-line
	}, [] )

	useEffect( () =>
	{
		setData( modelsList )
		setShowFilterOptions( false )
	}, [ modelsList ] )

	return (
		<div style={ { height: "100%" } }>
			{
				modelLoader === false ?
					<div style={ { position: "relative" } }>
						<AllModels models={ data } />
						<div onClick={ () => setShowFilterOptions( true ) } className='filter-box'>Filter</div>
						{ showFilterOptions && <div className="filter-tab">
							<Filter data={ data } category={ "models" } />
						</div> }
					</div>
					: <Loader />
			}
		</div>
	)
}

const mapPropsToState = ( state ) =>
{
	return {
		modelsList: state?.model?.models,
		modelLoader: state?.model?.modelsLoader,
	}
}

export default connect( mapPropsToState, { getAllModels } )( ( withRouter( Models ) ) );

