import axios from 'axios';
import Swal from 'sweetalert2';
import { serverUrl } from '../config/ApiDetails';

export const getAllTemplates = () => async ( dispatch, getState ) =>
{
	dispatch( { type: "TEMPLATE_LOADER" } )
	const admin = getState()?.admin?.adminData;
	console.log( admin )
	const token = await admin?.getIdToken()
	console.log( token )
	axios.get( `${serverUrl}/templates/getAll`, {
		headers: {
			'Authorization': "Bearer " + token
		}
	} ).then( ( res ) =>
	{
		console.log( res )
		const templates = res.data.templates
		dispatch( { type: "TEMPLATE_UPDATE", payload: { templates: templates } } )
	} )
		.catch( ( err ) =>
		{
			console.log( err )
			dispatch( { type: "TEMPLATE_LOADER" } )
		} )
}

export const createTemplate = ( templateDetails, history ) => async ( dispatch, getState ) =>
{
	dispatch( { type: "TEMPLATE_LOADER" } )
	const admin = getState()?.admin?.adminData;
	console.log( admin )
	axios.post( `${serverUrl}/templates/create`, templateDetails, {
		headers: {
			'Authorization': "Bearer " + admin.accessToken
		}
	} ).then( ( res ) =>
	{
		dispatch( { type: "TEMPLATE_LOADER" } )
		history.push( `/templates/editor/${res.data.template[ "_id" ]}` )
	} )
		.catch( ( err ) =>
		{
			console.log( err )
			Swal.fire( { title: err.message } )
			dispatch( { type: "TEMPLATE_LOADER" } )
		} )
}

export const deleteTemplate = ( templateId, callback ) => ( dispatch, getState ) =>
{
	dispatch( { type: "TEMPLATE_LOADER" } )
	const admin = getState()?.admin?.adminData;
	axios.delete( `${serverUrl}/templates/${templateId}`, {
		headers: {
			'Authorization': 'Bearer ' + admin.accessToken
		}
	} ).then( data =>
	{
		console.log( data );
		dispatch( { type: "TEMPLATE_LOADER" } )
		if ( data )
		{
			callback( { status: true } )
		} else
		{
			callback( { status: false } )
		}
	} ).catch( ( error ) =>
	{
		console.log( error )
		dispatch( { type: "TEMPLATE_LOADER" } )
		callback( { status: false } )
	} )
}