import React, { useEffect, useState } from 'react'
import AllProjects from '../../components/projects/AllProjectsComponents/AllProjects'
import { getAllProjects } from '../../actions/Projects'
import Loader from '../../components/loader/Loader'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Filter from '../../components/filter/Filter'

const Projects = ( { getAllProjects, projectsList, projectLoader } ) =>
{
	const [ data, setData ] = useState( [] );
	const [ showFilterOptions, setShowFilterOptions ] = useState( false )


	useEffect( () =>
	{
		getAllProjects();
		// eslint-disable-next-line
	}, [] )

	useEffect( () =>
	{
		setData( projectsList )
		setShowFilterOptions( false )
	}, [ projectsList ] )

	return (
		<div style={ { height: "100%" } }>
			{
				projectLoader === false ?
					<div style={ { position: "relative" } }>
						<AllProjects projects={ data } />
						<div onClick={ () => setShowFilterOptions( true ) } className='filter-box'>Filter</div>
						{ showFilterOptions && <div className="filter-tab">
							<Filter data={ data } category={ "projects" } />
						</div> }
					</div>
					: <Loader />
			}
		</div>
	)
}

const mapPropsToState = ( state ) =>
{
	return {
		projectsList: state?.project?.projects,
		projectLoader: state?.project?.projectsLoader,
	}
}

export default connect( mapPropsToState, { getAllProjects } )( ( withRouter( Projects ) ) );

