import React from 'react'
import plan1 from '../../../assets/images/plans/plan1.png'
import plan2 from '../../../assets/images/plans/plan2.png'
import plan3 from '../../../assets/images/plans/plan3.png'
import plan4 from '../../../assets/images/plans/plan4.png'
import { BsCheck2Circle, BsXCircle } from 'react-icons/bs'
import './PlanCard.css'

const PlanCard = ( { plan, index } ) =>
{
	const plansImages = [ plan1, plan2, plan3, plan4 ]
	return (
		<div className="plan_card">
			<div className="plan_title">
				<h5>{ plan[ "Item_Id" ] }</h5>
			</div>
			<div className="plan_details">
				<div className="plan_image">
					<img src={ plansImages[ index % 4 ] } alt="" />
				</div>
				{/* <div className="title">Free Plan</div> */ }
				<div className="plan_features">
					<ul>
						<li>{ plan[ "Views" ] }</li>
						<li> <span>{ plan[ "Storage" ] }MB</span> Storage</li>
						<li>Upto <span>{ plan[ "WebAR_Experience" ] }</span> WebAR Experiences</li>
						<li>Watermark { plan[ "Watermark" ] == true ? <BsCheck2Circle color="green" size="15" /> : <BsXCircle color="red" size="15" /> }</li>
						<li>Template  { plan[ "Templates" ] == true ? <BsCheck2Circle color="green" size="15" /> : <BsXCircle color="red" size="15" /> }</li>
						<li>Domain  { plan[ "Domain" ] == true ? <BsCheck2Circle color="green" size="15" /> : <BsXCircle color="red" size="15" /> }</li>
						<li>Analytics  { plan[ "Analytics" ] == true ? <BsCheck2Circle color="green" size="15" /> : <BsXCircle color="red" size="15" /> }</li>
						<li>Community Support  { plan[ "Community_Support" ] == true ? <BsCheck2Circle color="green" size="15" /> : <BsXCircle color="red" size="15" /> }</li>
						<li>Dedicated Support  { plan[ "Dedicated_Support" ] == true ? <BsCheck2Circle color="green" size="15" /> : <BsXCircle color="red" size="15" /> }</li>
						<li>No Code  { plan[ "No_Code" ] == true ? <BsCheck2Circle color="green" size="15" /> : <BsXCircle color="red" size="15" /> }</li>
					</ul>
				</div>
				<div className="price">
					<p><sup>$</sup><span>{ plan[ "Month_Price" ] }</span>/month</p>
					<h6>Billed { plan[ "Year_Price" ] }$ Per user annually</h6>
				</div>
			</div>
		</div>
	)
}

export default PlanCard