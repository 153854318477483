import React from 'react'
import './Feature.css'

const Features = () =>
{
	return (
		<div className="tab-content">
			<div
				className="tab-pane fade show active"
				id="block"
				role="tabpanel"
				aria-labelledby="block-tab"
			>
				<div className="tab" id="blocks"></div>
			</div>
			<div
				className="tab-pane fade"
				id="style"
				role="tabpanel"
				aria-labelledby="style-tab"
			>
				<div className="tab" id="styles-container"></div>
			</div>
			<div
				className="tab-pane fade"
				id="trait"
				role="tabpanel"
				aria-labelledby="trait-tab"
			>
				<div className="tab" id="trait-container"></div>
			</div>
		</div>
	)
}

export default Features