
export const styleManager = {
	appendTo: "#styles-container",
	sectors: [
		{
			name: "General",
			open: false,
			properties: [
				{
					name: "Custom-Font",
					property: "font-family",
					type: 'select',
					defaults: 'Poppins',
					list: [
						{
							value: "Helvetica Neue,Helvetica,Arial,sans-serif",
							name: 'Helvetica'
						},
						{ value: "'Oswald', sans-serif", name: 'Oswald' },
						{ value: "Century Gothic", name: 'Century Gothic' },
						{ value: "Helvetica Neue,Helvetica,Arial,sans-serif", name: 'Helvetica' },
						{ value: "sans-serif", name: 'sans-serif' },
						{ value: "Times New Roman", name: 'Times New Roman' },
						{ value: "Arial Black", name: 'Arial Black' },
						{ value: "Tahoma", name: 'Tahoma' },
						{ value: "Verdana, Geneva, sans-serif", name: 'Verdana' },
						{ value: "Courier New Courier, monospace", name: 'Courier New Courier' },
						{ value: "'Lato', sans-serif", name: 'Lato' },
						{ value: "'Open Sans', sans-serif", name: 'Open Sans' },
						{ value: "'Montserrat', sans-serif", name: 'Montserrat' },
						{ value: 'Great Vibes', name: 'Great Vibes' },
						{ value: 'Roboto', name: 'Roboto' },
						{ value: 'Noto Sans Mono', name: 'Noto Sans Mono' },
						{ value: 'Poppins', name: 'Poppins' },
					]
				},
				{
					type: 'number',
					label: 'Font size',
					property: 'font-size',
					units: [ 'px', '%', 'em', 'rem', 'vh', 'vw' ],
					min: 0,
				},
				{
					name: "Weight",
					property: "font-weight",
					type: "select",
					defaults: "normal",
					list: [
						{
							value: "normal",
							name: "Normal",
						},
						{
							value: "bold",
							name: "Bold",
						},
						{
							value: "bolder",
							name: "Bolder",
						},
						{
							value: "lighter",
							name: "Lighter"
						},
						{
							value: "100",
							name: "100",
						},
						{
							value: "200",
							name: "200",
						},
						{
							value: "300",
							name: "300",
						},
						{
							value: "400",
							name: "400",
						},
						{
							value: "500",
							name: "500",
						},
						{
							value: "600",
							name: "600",
						},
						{
							value: "700",
							name: "700",
						},
						{
							value: "800",
							name: "800",
						},
						{
							value: "900",
							name: "900",
						},
					],
				},
				{
					type: 'number',
					label: 'Letter Spacing',
					defaults: 'normal',
					property: 'letter-spacing',
					units: [ 'px', '%', 'em', 'rem', 'vh', 'vw' ],
				},
				{
					name: "Font color",
					property: "color",
					type: "color"
				},
				{
					type: 'number',
					label: 'Line Height',
					defaults: 'normal',
					property: 'line-height',
					units: [ 'px', '%', 'em', 'rem', 'vh', 'vw' ],
					min: 0,
				},
				{
					property: "text-align",
					type: "radio",
					defaults: "left",
					list: [
						{ value: "left", name: "Left", className: "fa fa-align-left" },
						{
							value: "center",
							name: "Center",
							className: "fa fa-align-center",
						},
						{ value: "right", name: "Right", className: "fa fa-align-right" },
						{
							value: "justify",
							name: "Justify",
							className: "fa fa-align-justify",
						},
					],
				},
				{
					type: 'stack',
					property: 'text-shadow',
					label: 'Text Shadow',
					// Additional props
					properties: [
						{ type: 'number', units: [ 'px' ], default: '0', property: 'x' },
						{ type: 'number', units: [ 'px' ], default: '0', property: 'y' },
						{ type: 'number', units: [ 'px' ], default: '0', property: 'blur' },
						{ type: 'color', default: 'black', property: 'color' },
					]
				},
				{
					property: "text-decoration",
					type: "radio",
					defaults: "none",
					list: [
						{ value: "none", name: "None", className: "fa fa-times" },
						{
							value: "underline",
							name: "underline",
							className: "fa fa-underline",
						},
						{
							value: "line-through",
							name: "Line-through",
							className: "fa fa-strikethrough",
						},
					],
				},
				{
					property: "border-radius",
					properties: [
						{ name: "Top", property: "border-top-left-radius" },
						{ name: "Right", property: "border-top-right-radius" },
						{ name: "Bottom", property: "border-bottom-left-radius" },
						{ name: "Left", property: "border-bottom-right-radius" },
					],
				},
				{
					type: 'composite',
					property: "box-shadow",
					properties: [
						{ name: "X position", type: "number", units: [ 'px' ], default: '0', property: "box-shadow-h" },
						{ name: "Y position", type: "number", units: [ 'px' ], default: '0', property: "box-shadow-v" },
						{ name: "Blur", type: "number", units: [ 'px' ], default: '0', property: "box-shadow-blur" },
						{ name: "Spread", type: "number", units: [ 'px' ], default: '0', property: "box-shadow-spread" },
						{ name: "Color", type: "color", property: "box-shadow-color" },

					],
				},
				{
					name: "Background color",
					property: "background-color",
					type: "color"
				},
				{
					id: "background-bg",
					property: "background",
					type: "bg",
				},
			],
		},
		{
			name: "Size",
			open: false,
			buildProps: [
				"width",
				"flex-width",
				"height",
				"max-width",
				"min-height",
				"margin",
				"padding",
			],
			properties: [
				{
					id: "flex-width",
					type: "integer",
					name: "Width",
					units: [ "px", "%" ],
					property: "flex-basis",
					toRequire: 1,
				},
				{
					property: "margin",
					properties: [
						{ name: "Top", property: "margin-top" },
						{ name: "Right", property: "margin-right" },
						{ name: "Bottom", property: "margin-bottom" },
						{ name: "Left", property: "margin-left" },
					],
				},
				{
					property: "padding",
					properties: [
						{ name: "Top", property: "padding-top" },
						{ name: "Right", property: "padding-right" },
						{ name: "Bottom", property: "padding-bottom" },
						{ name: "Left", property: "padding-left" },
					],
				},
			],
		},
	],
};
export const traitManager = {
	appendTo: "#trait-container",
};
export const selectorManager = {
	appendTo: "#styles-container",
};
export const panels = {
	defaults: [
		{
			id: "basic-actions",
			el: ".panel__basic-actions",
			buttons: [
				{
					id: "cmd-clear",
					className: "fa fa-trash",
					command: "cmd-clear",
				},
				{
					id: "undo",
					className: "fa fa-undo",
					command: "undo",
				},
				{
					id: "redo",
					className: "fa fa-repeat",
					command: "redo",
				},
				{
					id: 'open-code',
					className: 'fa fa-edit',
					attributes: {
						title: 'Open Code'
					},
					command: 'open-code',
					togglable: true,
				},
				{
					id: "preview",
					label: "Preview",
					command: "preview",
				},
			],
		},
		{
			id: "editor-actions",
			el: ".panel__editor",
			buttons: [
				{
					id: "saveDb",
					command: "saveDb",
					className: "saveDb",
					label: "Save",
				}
			],
		},
		{
			id: "panel-devices",
			el: ".panel__devices",
			buttons: [
				{
					id: "device-desktop",
					label: '<i class="fa fa-television"></i>',
					command: "set-device-desktop",
					togglable: false,
				},
				{
					id: "device-mobile",
					label: '<i class="fa fa-mobile"></i>',
					command: "set-device-mobile",
					togglable: false,
					active: true,
				},
				{
					id: "close-editor",
					label: '<i class="fa fa-window-close"></i>',
					command: "close-editor",
					togglable: false,
				}
			],
		},
	],
};
export const deviceManager = {
	devices: [
		{
			name: "Desktop",
			width: "",
		},
		{
			name: "Mobile",
			width: "320px",
			widthMedia: "480px",
		},
	],
};

export const addEditorCommand = ( editor, serverUrl, projectId, user, history ) =>
{
	// Commands
	editor.Commands.add( "set-device-desktop", {
		run: ( editor ) => editor.setDevice( "Desktop" ),
	} );
	editor.Commands.add( "set-device-mobile", {
		run: ( editor ) => editor.setDevice( "Mobile" ),
	} );
	editor.Commands.add( "close-editor", {
		run: ( editor ) =>
		{
			history.push( "/templates" )
		}
	} )


	// Save Button
	editor.Commands.add( "saveDb", {
		run: async ( editor, sender ) =>
		{
			sender && sender.set( "active" );
			console.log( "Save Db" )
			await editor.store();
		},
	} );

	//Clear Button
	editor.Commands.add( "cmd-clear", {
		run: ( editor ) =>
		{
			editor.DomComponents.clear();
			editor.CssComposer.clear();
		},
	} );

	//Undo
	editor.Commands.add( "undo", {
		run: ( editor ) => editor.UndoManager.undo(),
	} );

	// Redo
	editor.Commands.add( "redo", {
		run: ( editor ) => editor.UndoManager.redo(),
	} );

};

export const storageSetting = () =>
{
	return {
		type: "remote",
		stepsBeforeSave: 1,
		autoload: true,
		autosave: false,
		contentTypeJson: true,
		storeComponents: true,
		storeStyles: true,
		storeHtml: true,
		storeCss: true,
		headers: {
			"Content-Type": "application/json",
		},
	};
};

export const scripts = [
	'https://code.jquery.com/jquery-3.3.1.slim.min.js',
	'https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.3/umd/popper.min.js',
	'https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/js/bootstrap.min.js',
	"https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js",
	"https://cdnjs.cloudflare.com/ajax/libs/grapesjs/0.19.4/grapes.min.js",
	"https://unpkg.com/grapesjs-blocks-flexbox",
	"https://unpkg.com/grapesjs-component-code-editor",
];
export const styles = [
	'https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css',
	"https://fonts.googleapis.com/css2?family=Great+Vibes&family=Noto+Sans+Mono:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,200;0,300;0,400;0,700;0,900;1,500&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap",
	"https://cdnjs.cloudflare.com/ajax/libs/grapesjs/0.19.4/css/grapes.min.css",
	"grapesjs-component-code-editor/dist/grapesjs-component-code-editor.min.css"
];

export const toggleSidebar = ( fromEditor ) =>
{
	const sidebar = document.getElementById( "navbar" );
	const mainContent = document.getElementById( "main-content" );
	if ( fromEditor )
	{
		sidebar.classList.remove( "d-flex" );
		sidebar.classList.add( "d-none" );
		mainContent.classList.remove( "w-85", "start-15" );
	} else if ( sidebar.classList.contains( "d-flex" ) )
	{
		sidebar.classList.remove( "d-flex" );
		sidebar.classList.add( "d-none" );
		mainContent.classList.remove( "w-85", "start-15" );
	} else
	{
		sidebar.classList.remove( "d-none" );
		sidebar.classList.add( "d-flex" );
		mainContent.classList.add( "w-85", "start-15" );
	}
};
